import React, { useState,useContext } from 'react'

import axios from 'axios';


import { profileContext } from '../../contexts/account'
import { messengerContext } from '../../contexts/messages';



import { HiExclamation } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';





const MessageModlar = ({data,editModal}:{data:{id:number,price:number,name:string},editModal:Function})=>{
  const { profile } = useContext(profileContext)
  const { updateMessages } = useContext(messengerContext)
  const [ message , setMesage ] = useState<string>("")
  const navigate = useNavigate()
  //check if student already enrolled

  const handleMessage = () =>{
    const datar ={
      message:message,
      from:profile.id,
      to:data.id,
      isgroup:0,
    }
    axios.post('/message/send',datar)
      .then(resp =>{
        updateMessages()
        navigate('/messages')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong , try again later')
      })
  }

  return(
    <div className="flex items-center min-h-screen px-4 py-8">
      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
        {
          //check if user is logged or not
          profile.id ? 
          <div className="mt-3 sm:flex">
            <div className="mt-2 text-center sm:ml-4 sm:text-left w-96">
                <h4 className="text-lg font-medium text-gray-800">
                    Sending Mesage to {data.name} 
                </h4>
                <div className="w-full">
                  <textarea className="w-full mt-4 mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none form-control block px-3 py-1.5 text-base font-normal bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0" 
                    rows={3}
                    placeholder="Your message"
                    value={message}
                    onChange={(e)=>setMesage(e.target.value)}
                  />
                </div>                
                <div className="items-center gap-2 mt-3 sm:flex">
                    <button onClick={handleMessage} className="w-full mt-2 p-2.5 flex-1 text-sub-a bg-main-b  hover:opacity-90 rounded-md outline-none ring-offset-2 ring-sub-a focus:ring-2">
                        Send
                    </button>
                    <button onClick={() => editModal({type:0,active:false})} className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                    >
                        Cancel
                    </button>
                </div>
            </div>
          </div>
          //check if the user type is a student
          :
          <div className="mt-3 sm:flex">
              <div className="text-red-600 flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                <HiExclamation className='h-6 w-6' />
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-medium text-gray-800">
                      You have not logged in yet!
                  </h4>
                  <p className="mt-2 text-sm leading-relaxed text-gray-500">
                    You have to log in if you want to send messages to others
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex">
                      <Link to={'/login'} className="text-center w-full mt-2 p-2.5 flex-1 text-white bg-red-600  hover:opacity-90 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2">
                          Login
                      </Link>
                      <button className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                      >
                          Cancel
                      </button>
                  </div>
              </div>
          </div>
        }
      </div>
    </div>
  )
}

export default MessageModlar