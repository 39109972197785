import React,{ useState , useEffect } from 'react'

import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation,Autoplay } from "swiper";


import { GoSearch } from 'react-icons/go'
import { AiFillStar } from 'react-icons/ai'
import { BsFillPersonFill } from 'react-icons/bs'
import { MdPlayLesson } from 'react-icons/md'




import IconInput from '../../components/iconinput'
import axios from 'axios';

interface teacherItemProps{
  full_name:string;
  userid:number;
  image:string;
  created_at:string;
  search?:boolean;
}

const TeacherItem = ({userid,full_name,image,search,created_at}:teacherItemProps) =>{
  const [ data , setData ] = useState({rating:0,snum:0})
  const [course , setCourses] = useState([])
  useEffect(()=>{
    //get teacher data
    const getData = async () =>{
      try{
        const coursesData = await axios.get(`/coursesbyid/${userid}`)
        const getRating = await axios.get(`/rate/getrate/${userid}`)
        const getStudents = await axios.get(`/coursestudentsofteacher/${userid}`)
        setData({rating:getRating.data ? getRating.data / 2 : 0,snum:getStudents.data.length ? getStudents.data.length : 0})
        setCourses(coursesData.data)
      }catch(err){
        console.log(err)
      }
    }
    getData()
  
    },[userid])
  

  return(
    <Link to={`/teacher/${userid}`} className={search ? 'w-full group flex justify-center content-start flex-wrap pt-12 flex-row rounded-md h-full text-center lg:w-1/3 mt-12' : 'group flex justify-center content-start flex-wrap pt-12 flex-row rounded-md h-full w-full'}>
      <div className='w-full flex justify-center absolute -mt-12 '>
        <img src={`https://clashingblades.com/api/teachapi/uploads/${image}`} alt={full_name} className="h-40 w-40 rounded-full border-2 border-sub-a group-hover:border-r-main-a group-hover:border-l-main-a transition-colors duration-300 "/>
      </div>
      <div className='bg-main-b w-full h-full rounded-lg shadow-lg pt-36 pb-6'>
        <div className='text-sub-a text-2xl group-hover:underline  transition-colors duration-300 group-hover:text-main-a'>{full_name}</div>
        <div className='mt-4 flex flex-row flex-wrap justify-center '>
          <div className='w-full flex justify-center lg:justify-center items-center text-lg my-2'> 
            <div className='mr-4 text-sub-a'><AiFillStar/> </div>
            <div className='font-medium text-white'> {data.rating} Rating </div>
          </div>
          <div className='w-full flex justify-center lg:justify-center items-center text-lg my-2'> 
            <div className='mr-4 text-sub-a'><BsFillPersonFill/> </div>
            <div className='font-medium text-white'>{data.snum} Students </div>
          </div>
          <div className='w-full flex justify-center lg:justify-center items-center text-lg my-2'> 
            <div className='mr-4 text-sub-a'><MdPlayLesson/> </div>
            <div className='font-medium text-white'>{course.length ? course.length : 0} Courses </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

const TeachersPage = ()=>{
  const [teachers , setTeachers] = useState<Array<teacherItemProps>>([])
  const [ searchTeach , setSearchTeach ] = useState<Array<teacherItemProps>>([])
  const [search , setSearch] = useState<string>("")
  const [isSearching , setIsSearching] = useState<boolean>(false)

  useEffect(()=>{
    //get top 10 teachers when page first initialized  
    const getData = async () =>{
      try{
        const getTeachers = await axios.get(`/getteachers/top`)
        setTeachers(getTeachers.data)
      }catch(err){
        console.log(err)
      }
    }
    getData()
  
    },[])

  const handleSearch = async (e:React.ChangeEvent<HTMLInputElement>) =>{
    //this is used to enable search mode when there is a search
    const text = e.target.value
    setSearch(text)
    if(text.length > 0 ){
      const getTeachers = await axios.get(`/teachers/searching/${text}`)
      setSearchTeach(getTeachers.data)
      setIsSearching(true)
    }else{
      setIsSearching(false)
    }
  }  

  return(
    <section>
      <div className='courses h-96 flex flex-wrap flex-row justify-center content-center items-center text-center px-4 lg:px-12' style={{backgroundImage: 'url("/assets/courses_header.svg")'}}>
        <div className='w-full text-6xl font-medium mb-4'>Teachers</div>
        <div className='text-xl max-w-2xl mt-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod autem error quisquam unde aspernatur delectus totam adipisci.</div>
      </div>
      <div className='min-h-screen bg-white'>
        <div className='w-full bg-main-b flex px-8 justify-center py-6'>
          <div className='w-10/12 lg:w-5/12  flex justify-center items-center'>
            <IconInput value={search} onChange={handleSearch} type="text" placeholder='Search for a teacher ....' icon={GoSearch} iconClass="text-main-b text-xl" />         
          </div>
        </div>
        <div className='flex flex-wrap justify-center gap-3 px-4 mb-10 lg:mb-2'>
          {
            !isSearching ?
            <Swiper className="h-96 w-full mt-4" breakpoints={{
              640: {slidesPerView: 2,spaceBetween: 20,},
              1200: {slidesPerView: 3,spaceBetween: 50,},
              1500: {slidesPerView: 4,spaceBetween: 50,},          
            }} loop navigation={true} spaceBetween={30} autoplay={{delay: 2500,disableOnInteraction: false,}}
              modules={[Navigation,Autoplay]} >
            {
                teachers.length ?
                  teachers.map(teacher=>(
                    <SwiperSlide key={teacher.userid}>
                      <TeacherItem key={teacher.userid} {...teacher } />
                    </SwiperSlide>
                  ))
                  : null
                }
            </Swiper>            
            : 
            searchTeach.length > 0 ?
            searchTeach.map(teacher=>
              <TeacherItem search key={teacher.userid} {...teacher } />
            ):
            <div>
              There is no teacher with this information
            </div>
          }
        </div>
      </div>
    </section>
  )
}



export default TeachersPage