import React , { useState,useEffect, useContext } from 'react'

import axios from 'axios'

import { Link, useNavigate} from 'react-router-dom'

import { AiFillStar ,AiFillBook } from 'react-icons/ai'
import { BsCalendarDateFill,BsFillPencilFill } from 'react-icons/bs'
import { MdPlayLesson,MdLogout,MdAdminPanelSettings } from 'react-icons/md'
import { FaWallet } from 'react-icons/fa'
import {RiCoupon2Fill} from 'react-icons/ri'

import Button from '../../components/button'
import Modlar from '../../components/modlar'

import { profileContext } from '../../contexts/account'






const StudentUserPage = ()=>{
  const { profile,logOut } = useContext(profileContext)
  const navigate = useNavigate()
  const [ modal , setModal] = useState<{type:number,active:boolean}>({type:1,active:false}) // used to open Modal when it needed
  const [ lessons , setLessons ] = useState<number>(0)
  const [ pcourses , setPcourses] = useState<Array<courseItemsProps>>([])

  //this useeffect is used to check if user is logged on or not , the check happens when you enter the page or the profile context change
  //if user is not logged it will send him to login page
  useEffect(()=>{
    const getData = async () =>{
      const getPcourses = await axios.get(`/studentprivatecourses/${profile.id}`)
      setPcourses(getPcourses.data.length ? getPcourses.data : [])
    }
    try{
    getData()
    }catch(err){
      console.log(err)
    } 
    if(profile.courses){
      if(profile.courses.length > 0){
        let les = 0
        profile.courses.map((course:{lessons:number})=>{
          les = les + course.lessons
          return 0
        })
        setLessons(les)
      }
    }
  },[profile,navigate])
  useEffect(()=>{
    try{
     
    }catch(err){
      console.log(err)
    }
  },[])
  return profile.id ?(
    <section>
      <div className='courses h-72 flex flex-wrap flex-row justify-center content-center items-center text-center px-4 lg:px-12' style={{backgroundImage: 'url("/assets/courses_header.svg")'}}>
      </div>
      <div className='min-h-screen bg-sub-b flex justify-center px-4 lg:px-32'>
        <div className='max-w-screen-xl w-full flex flex-wrap flex-row content-start  '>
          <div className='w-4/12 lg:w-3/12 flex justify-center'>
            <img src={`https://clashingblades.com/api/teachapi/uploads/${profile.image}`} alt="profile" className='h-24 w-24 lg:h-48 lg:w-48 rounded-full border-8 border-sub-b -mt-12' />
          </div>
          <div className='w-8/12 lg:w-4/12 flex flex-col'>
            <div className='text-4xl font-bold my-3'>{profile.full_name}</div>
            <div className='font-medium'>{lessons} Lessons</div>
            <div>
              <Button  onClick={logOut} className="flex my-4 justify-center items-center bg-red-500  hover:bg-sub-a hover:text-red-500" >
                <MdLogout className='mr-3'/>
                Logout
              </Button>
            </div>
          </div>
          <div className='w-full lg:w-5/12 lg:mt-0 mt-4 flex justify-center items-center'>
            <div className='w-full flex justify-center'>
              <Button onClick={()=>setModal({type:1,active:true})}  complete variant={2} className="flex mx-3 px-4 justify-center items-center" >
                <BsFillPencilFill className='mr-3'/>
                Edit Profile
              </Button>
              <Button onClick={()=>setModal({type:2,active:true})} complete variant={3} className="flex mx-3 px-4 justify-center items-center" >
                <RiCoupon2Fill className='mr-3'/>
                Use Coupon
              </Button>
            </div>
          </div>

          <div className='bg-main-b w-full lg:w-4/12 h-96 px-8 rounded-lg mt-12'>
            <div className='w-full mt-4 text-center lg:text-left'>
              <div className='mt-4 flex flex-row flex-wrap text-left'>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><AiFillStar/> </div>
                  <div className='font-medium text-white w-80'> {profile.rating} Rating </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><AiFillBook/> </div>
                  <div className='font-medium text-white w-80'>{lessons} Lessons </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><MdPlayLesson/> </div>
                  <div className='font-medium text-white w-80'>{profile.courses ? profile.courses.length : 0} Courses </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><BsCalendarDateFill/> </div>
                  <div className='font-medium text-white w-80'>Joined Since {profile.created_at.split("T")[0]} </div>
                </div>
              </div>
            </div>
            <div className='w-full flex justify-center lg:justify-start items-center text-lg my-12'> 
              <div className='mr-4 text-sub-a w-6'><FaWallet/> </div>
              <div className='font-medium text-white w-80 text-3xl text-center'>$ {profile.balance} </div>
            </div>
          </div>
          <div className='w-full lg:w-8/12 pl-8 flex justify-center'>
            <div className='w-full flex flex-wrap flex-row justify-evenly max-w-screen-xl my-4'>
              {
                pcourses.length > 0 ?
                pcourses.map((course: JSX.IntrinsicAttributes & courseItemsProps)=>(
                  <CourseItem key={course.id} {...course } isPrivate />
                ))
                : null                
              }

              {
                profile.courses.length > 0 ?                
                profile.courses.map((course: JSX.IntrinsicAttributes & courseItemsProps)=>(
                  course.state === 1 ?
                  <CourseItem key={course.id} {...course } />
                  //key is must value for react so It can differenetial between diffrent data
                  // {...course} this is spreading other prorpties better than doing it manualy*/
                  : null
                  //if its canceled course then dont show it

                  ))
                :
                <div>
                  You have not added any course yet
                </div>
              }
            </div>
          </div>          
        </div>
      </div>
      {
        //check if he is admin
        profile.type === 27 ?(
          <Link to={"/admin"} className='group flex flex-row justify-center items-center h-16 w-16  text-center bg-main-a fixed bottom-2 right-2 rounded-full'>
            <MdAdminPanelSettings className='h-12 w-12 text-sub-a group-hover:text-sub-b' />
          </Link>    
        ):null
      }
      <Modlar  type={modal.type} active={modal.active} editModal={setModal}  />     
    </section>
  )
  : (
    <section>
      You will have to login first
    </section>
  )
}

interface courseItemsProps{
  id:number;
  name:string;
  material_id:number;
  price:number;
  teacher_id:number;
  startTime:string;
  period:string;
  meetings_number:number;
  description:string;
  state?: number
  isPrivate?:boolean;

}

//This is the course item which is displayed with every course item
const CourseItem = ({id,name,material_id,description,price,teacher_id,period,startTime,meetings_number,isPrivate}:courseItemsProps) =>{
  const [material , setMaterial ] = useState<{id:number;name:string;image:string,field:number}>({
    id:0,
    name:'Teach Online',
    image:'black.jpg',
    field:0
  })
 
  useEffect(()=>{
    //get course's material when first inilized 
    const getData = async () =>{
      const getMaterial = await axios.get(`/material/${material_id}`)
      setMaterial(getMaterial.data)
    }
    getData()
  },[material_id])
  return isPrivate ? (
    <div className='w-full lg:w-6/12 px-8 my-4 lg:p-4 hover:-translate-y-2 hover:cursor-pointer transition-transform duration-400'>
      <Link to={`/privatecourse/${id}`} className='w-full h-80 text-sub-a rounded-md bg-main-a flex flex-wrap flex-row justify-center items-center content-center'>
        <div className='w-20 h-20'>
          <img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt="material logo" className='h-full w-full' />
        </div>
        <div className='w-full text-2xl mt-10 text-center'>
          {name}
        </div>
      </Link>
    </div>
    )
    :(
    <div className='w-full lg:w-6/12 px-8 my-4 lg:p-4 hover:-translate-y-2 hover:cursor-pointer transition-transform duration-400'>
      <Link to={`/course/${id}`} className='w-full h-80 text-sub-a rounded-md bg-main-b flex flex-wrap flex-row justify-center items-center content-center'>
        <div className='w-20 h-20'>
          <img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt="material logo" className='h-full w-full' />
        </div>
        <div className='w-full text-2xl mt-10 text-center'>
          {name}
        </div>
      </Link>
    </div>
  )
}



  export default StudentUserPage