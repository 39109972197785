import React,{ useRef ,RefObject , useEffect , useState, useContext} from 'react'

import Button from './button';

import { IoPersonCircle } from 'react-icons/io5'
import { AiFillMessage } from 'react-icons/ai'

import { Link , useLocation } from 'react-router-dom';//Link is must to route between pages

import { profileContext } from '../contexts/account'
import { messengerContext } from '../contexts/messages'

interface linkProps {
  name: string;
  to: string;
  handleHovering:Function;
  navhover:boolean;
  mob?:boolean;
}

const NavLink = ({name,to,handleHovering,navhover,mob}:linkProps) =>{
  const main = useRef<HTMLAnchorElement|null>(null)//We using useref to refernece the link html element
  const location = useLocation();
  useEffect(()=>{//useEffect is used that when path is changed or element is hovered it will be selected 
    if(location.pathname===to && !navhover && !mob){
      handleHovering(main)
    }

  },[location,to,handleHovering,navhover,mob])
  return(
    <Link ref={main} onMouseEnter={()=>handleHovering(main)} className="text-base py-2 lg:py-6 uppercase font-medium text-main-a hover:text-main-b" to={to}> 
      {name}
    </Link>
  )
}

//tailwind recommend using this for logical classnames
function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}


const Navbar = () =>{
  const [barWidth,setWidth] = useState<number>(0)
  const [barLeft , setLeft] = useState<number>(600)
  const [navhover , setNavhover] = useState<boolean>(false)
  const location = useLocation();
  const [mobileNav, setMobileNav] = useState(false)
  const { profile } = useContext(profileContext)
  const { messages } = useContext(messengerContext)
  useEffect(()=>{
    if(location.pathname === '/login' || location.pathname === '/signup' ){
      setNavhover(false)
      setWidth(0)
    }
  },[location])

  const handleHovering = (el : RefObject<HTMLDivElement>)=>{
    let width = barWidth
    let left = barLeft
    width =el.current!.offsetWidth+10
    left = el.current!.offsetLeft-5
    setWidth(width)
    setLeft(left)
  }

  return(
    <header className="relative bg-sub-a" >
      <div className="mx-auto px-4 sm:px-10 relative" onMouseEnter={()=>setNavhover(true)/*when mouse hover over the links section of navbar */}>
        <div  className="flex justify-between items-center md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1 ">
            <Link to="/" className='py-2'>
              <span className="sr-only">Online Teach</span>
              <img
                className="h-12 w-24  md:h-12 md:w-28"
                src="/assets/logo.png"
                alt=""
              />
            </Link>
          </div>
          <div className="lg:hidden">
            <button className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                onClick={() => setMobileNav(!mobileNav)}
            >
                {
                    mobileNav ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                        </svg>
                    )
                }
            </button>
          </div>
          <nav className="hidden md:flex space-x-10" onMouseLeave={()=>setNavhover(false)} >
            <NavLink navhover={navhover} handleHovering={handleHovering} name="Home" to="/" />
            <NavLink navhover={navhover} handleHovering={handleHovering} name="Courses" to="/courses" />
            <NavLink navhover={navhover} handleHovering={handleHovering} name="Teachers" to="/teachers" />
            <NavLink navhover={navhover} handleHovering={handleHovering} name="Materials" to="/materials" />
            <NavLink navhover={navhover} handleHovering={handleHovering} name="about" to="/about" />
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          {
              //if user is logged show messnger else show signup button
              profile.id ? (
              <button className='text-main-b mr-4 relative'>
                <Link to="/messages">
                  <AiFillMessage className='h-11 w-11'/>            
                </Link>
                {
                  messages.length ?
                  <div className='absolute flex text-xs bg-red-600 -top-1 -right-1 text-white rounded-full justify-center text-center items-center h-6 w-6 '>
                    {messages.length}
                  </div>
                  : null
                }
              </button>
              ):
              (
              <button className='text-5xl text-main-b mr-4'>
                <Link to="/login">
                  <IoPersonCircle/>            
                </Link>
              </button>
             )          
            } 

            {
              //if user is logged show messnger else show signup button
              profile.id ? (
              <button className='text-5xl text-main-b mr-4'>
                <Link to="/profile">
                  <IoPersonCircle/>            
                </Link>
              </button>
              ):
              (
              <Button variant={2} complete>
                <Link to="/signup">
                  Sign Up            
                </Link>
              </Button>  
             )          
            }            

          </div>
        </div>
          <div className={`lg:hidden flex-1 justify-between flex-row-reverse lg:overflow-visible lg:pb-0 lg:pr-0 lg:h-auto ${ mobileNav ? 'pb-10 overflow-auto pr-4' : 'hidden'}`}>
            <nav className='grid gap-y-2'>
              <NavLink mob navhover={navhover} handleHovering={handleHovering} name="Home" to="/" />
              <NavLink mob navhover={navhover} handleHovering={handleHovering} name="Courses" to="/courses" />
              <NavLink mob navhover={navhover} handleHovering={handleHovering} name="Teachers" to="/teachers" />
              <NavLink mob navhover={navhover} handleHovering={handleHovering} name="Materials" to="/materials" />
              <NavLink mob navhover={navhover} handleHovering={handleHovering} name="about" to="/about" />
            </nav>
            <Button variant={2} className='w-full my-2'>
              <Link to="/login">
                Login           
              </Link>
            </Button>            
            {
              //if user is logged show messnger else show signup button
              profile.id ? (
                <Button variant={2} className='w-full my-2 relative'>
                <Link to="/messages">
                  Messenger           
                </Link>
              </Button>  
              ):
              (
              <Button variant={2} complete className='w-full my-2'>
                <Link to="/signup">
                  Sign Up            
                </Link>
              </Button>   
             )          
            }
            
          </div>
        <div style={{width:barWidth,left:barLeft}} className={classNames(navhover&&barWidth>1 ? 'block' : '','hidden md:block h-1 bottom-0 duration-500 absolute bg-main-b overflow-hidden transition-[width,height,left] rounded-bl-sm rounded-br-sm')} ></div>
      </div>
    </header>

  )
}

export default Navbar