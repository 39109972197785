
import { createContext , useCallback, useContext } from 'react';

import axios from 'axios';


import { useLocalStorage } from '../hooks/useLocalStorage'
import { profileContext } from './account';
//import { isObjectEqual } from '../hooks/Utils'


export const messengerContext = createContext();


export default function Messenger({ children }) {
  const [messages, setMessages] = useLocalStorage("messages", [])
  const { profile } = useContext(profileContext)


  const  updateMessages = useCallback(
    async () =>{
      const coursesids = profile.courses.map(course=>course.id * -1)
      const getMessages = await axios.post(`/message/getmessages`,{userid:profile.id,courses:coursesids})
      if(messages.length !== getMessages.data.length ){
        setMessages(getMessages.data)
      }      
    }    
  ,[profile,setMessages,messages])

  const  ForceUpdateMessages = useCallback(
    async () =>{
      const coursesids = profile.courses.map(course=>course.id * -1)
      const getMessages = await axios.post(`/message/getmessages`,{userid:profile.id,courses:coursesids})
      setMessages(getMessages.data)
    
    }    
  ,[profile,setMessages])

 

  return (
    <messengerContext.Provider value = {{messages,updateMessages,ForceUpdateMessages}}>
      {children}  
    </messengerContext.Provider>
  );
}

