import React , { useState,useEffect, useContext } from 'react'

import axios from 'axios'

import { Link,useParams,useNavigate } from 'react-router-dom'

import { GiTeacher } from 'react-icons/gi'
import { AiFillStar ,AiFillMessage } from 'react-icons/ai'
import { BsFillPersonFill,BsCalendarDateFill,BsFillBriefcaseFill } from 'react-icons/bs'
import { MdPlayLesson } from 'react-icons/md'


import Button from '../../components/button'
import Rate from '../../components/rating'
import { profileContext } from '../../contexts/account'
import Modlar from '../../components/modlar'


interface courseItemsProps{
  id:number;
  name:string;
  material_id:number;
  price:number;
  teacher_id:number;
  startTime:string;
  period:string;
  meetings_number:number;
  description:string;
  isPrivate?:boolean;
}


//This is the course item which is displayed with every course item
const CourseItem = ({id,name,material_id,description,price,teacher_id,period,startTime,meetings_number,isPrivate}:courseItemsProps) =>{
  const [material , setMaterial ] = useState<{id:number;name:string;image:string,field:number}>({
    id:0,
    name:'Teach Online',
    image:'black.jpg',
    field:0
  })
 
  useEffect(()=>{
    //get course's material when first inilized 
    const getData = async () =>{
      const getMaterial = await axios.get(`/material/${material_id}`)
      setMaterial(getMaterial.data)
    }
    getData()
  },[material_id])
  return isPrivate ? (
    <div className='w-full lg:w-6/12 px-8 my-4 lg:p-4 hover:-translate-y-2 hover:cursor-pointer transition-transform duration-400'>
      <Link to={`/privatecourse/${id}`} className='w-full h-80 text-sub-a rounded-md bg-main-a flex flex-wrap flex-row justify-center items-center content-center'>
        <div className='w-20 h-20'>
          <img src={`https://clashingblades.com/teachapi/uploads/${material.image}`} alt="material logo" className='h-full w-full' />
        </div>
        <div className='w-full text-2xl mt-10 text-center'>
          {name}
        </div>
      </Link>
    </div>
  )
  :
  (
    <div className='w-full lg:w-6/12 px-8 my-4 lg:p-4 hover:-translate-y-2 hover:cursor-pointer transition-transform duration-400'>
      <Link to={`/course/${id}`} className='w-full h-80 text-sub-a rounded-md bg-main-b flex flex-wrap flex-row justify-center items-center content-center'>
        <div className='w-20 h-20'>
          <img src={`https://clashingblades.com/teachapi/uploads/${material.image}`} alt="material logo" className='h-full w-full' />
        </div>
        <div className='w-full text-2xl mt-10 text-center'>
          {name}
        </div>
      </Link>
    </div>
  )
}



const StudentImage = ({userid,full_name,image}:{userid:number;full_name:string;image:string})=>{
  return(
    <Link to={`/student/${userid}`} className="first:m-0 -ml-2 border border-main-a h-12 w-12 rounded-full overflow-hidden">
      <img src={`https://clashingblades.com/teachapi/uploads/${image}`} alt={full_name} className='h-full w-full'  />  
    </Link>
  )  
}


const TeacherProfilePage = ()=>{
  const [course , setCourse] = useState<Array<courseItemsProps>>([])
  const [teacher , setTeacher ] = useState<{id:number;full_name:string;image:string;education_field:number;exp_years:number;created_at:string;}>({id:0,full_name:'Teacher Online',image:'',education_field:0,exp_years:4,created_at:'2022-2-22'}) 
  const [eduF , setEduF ] = useState<{id:number;name:string;}>({id:0,name:'Teaching Online',}) 
  const [ modal , setModal] = useState<{type:number,active:boolean,data:any}>({type:1,active:false,data:{}}) // used to open Modal when it needed
  const [ students , setStudents] = useState<Array<{userid:number;full_name:string;image:string;}>>([])
  const [ rating , setRating ] = useState<number>(0)
  const [showPr , setShowPr] = useState<boolean>(false)
  const [ pcourses , setPcourses] = useState<Array<courseItemsProps>>([])
  const { profile } = useContext( profileContext )
  const { id } = useParams();
  const navigate = useNavigate()

useEffect(()=>{
  const getData =  async () =>{
    try{
      //get data when the component is opened
      const getTeacher = await axios.get(`/teacher/${id}`)
      const getCourses = await axios.get(`/coursesbyid/${id}`)
      const getEduf = await axios.get(`/fields/${getTeacher.data.field}`)
      const getStudents = await axios.get(`/coursestudentsofteacher/${id}`)
      const getRating = await axios.get(`/rate/getrate/${id}`)
      const getPcourses = await axios.get(`/privatecoursesbyid/${id}`)
      setPcourses(getPcourses.data.length ? getPcourses.data : [])      
      setRating(getRating.data/2)
      setCourse(getCourses.data)
      setStudents(getStudents.data.length ? getStudents.data : [])
      setTeacher(getTeacher.data)
      setEduF(getEduf.data)
    }catch(err){
      // alert('User dosent exists')
      // navigate('/')
      console.log(err)
    }

  }
  getData()

},[id,navigate])

  const deleteUser = () =>{
    //only works if user is admin
    if(profile.type === 27){
      const sentdata ={
        type:profile.type,
        uid:id,
        utype:2,
      }
      axios.post('/admin/deleteuser',sentdata)
        .then(resp =>{
          alert('User has been deleted successfully')
          navigate('/admin')
      }).catch(err =>{
        console.log(err)
        alert('Something went wrong please try again later')
        })
    }
  }
  return(
    <section>
      <div className='courses h-72 flex flex-wrap flex-row justify-center content-center items-center text-center px-4 lg:px-12' style={{backgroundImage: 'url("/assets/courses_header.svg")'}}>
      </div>
      <div className='min-h-screen bg-sub-b flex justify-center px-4 lg:px-32'>
        <div className='max-w-screen-xl w-full flex flex-wrap flex-row content-start  '>
          <div className='w-4/12 lg:w-3/12 flex justify-center'>
            <img src={`https://clashingblades.com/teachapi/uploads/${teacher.image}`} alt="profile" className='h-24 w-24 lg:h-48 lg:w-48 rounded-full border-8 border-sub-b -mt-12' />
          </div>
          <div className='w-8/12 lg:w-4/12 flex flex-col'>
            <div className='text-4xl font-bold my-3'>{teacher.full_name}</div>
            <div className='font-medium'>{students.length} Students</div>
            <div className='flex flex-row mt-2'>
            {
                students.length > 0 ?
                students.slice(0,4).map(std =>(
                  <StudentImage key={std.userid} {...std} />
                ))
                : null
              }
            </div>
          </div>
          <div className='w-full lg:w-5/12 lg:mt-0 mt-4 flex justify-center items-center'>
            <div className='w-full flex justify-center'>
              {
                //only show button if admin
                profile.type === 27 ?
                <Button onClick={deleteUser} complete variant={2} className="flex mx-3 px-4 justify-center items-center bg-red-600 hover:border-red-600 hover:text-red-600 " >
                  Delete User
                </Button>
                :null                
              }
              <Button onClick={()=>setModal({type:9,active:true,data:{id:id,name:teacher.full_name}})}  complete variant={2} className="flex mx-3 px-4 justify-center items-center" >
                <AiFillMessage className='mr-3'/>
                Message
              </Button>
              <Button onClick={()=>setShowPr(!showPr)} complete variant={3} className="flex mx-3 px-4 justify-center items-center" >
                <GiTeacher className='mr-3'/>
                Private Tutor
              </Button>
            </div>
          </div>
          <div className='bg-main-b w-full lg:w-4/12 h-96 px-8 rounded-lg mt-12'>
            <div className='w-full mt-4 text-center lg:text-left'>
              <div className='mt-4 flex flex-row flex-wrap text-left'>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><AiFillStar/> </div>
                  <div className='font-medium text-white w-80'> {rating} Rating </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><BsFillPersonFill/> </div>
                  <div className='font-medium text-white w-80'>{students.length ? students.length :0 } Students </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><MdPlayLesson/> </div>
                  <div className='font-medium text-white w-80'>{course.length > 0 ? course.length : 0} Courses </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><BsCalendarDateFill/> </div>
                  <div className='font-medium text-white w-80'>Joined Since {teacher.created_at.split("T")[0]} </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><BsFillBriefcaseFill/> </div>
                  <div className='font-medium text-white w-80'>Specialize at {eduF.name} for {teacher.exp_years} years </div>
                </div>
              </div>

            </div>
            <div className='w-full mt-4'>
              <Rate oldrate={rating}  userid={id} />
            </div>
          </div>
          <div className='w-full lg:w-8/12 pl-8 flex justify-center'>
            <div className='w-full flex flex-wrap flex-row justify-evenly max-w-screen-xl my-4'>
            {
                showPr ?
                pcourses.length > 0 ?
                pcourses.map((course: JSX.IntrinsicAttributes & courseItemsProps)=>(
                  <CourseItem key={course.id} {...course } isPrivate />
                ))
                : null  
                : null              
              }              
              {
                course.length > 0 ?
                course.map(course=>(
                  <CourseItem key={course.id} {...course } />
                  //key is must value for react so It can differenetial between diffrent data
                  // {...course} this is spreading other prorpties better than doing it manualy*/
                ))
                : null
              }
            </div>
          </div>          
        </div>

      </div>
      <Modlar type={modal.type} active={modal.active} editModal={setModal} data={modal.data}  />     
    </section>
  )
}



export default TeacherProfilePage