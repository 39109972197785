import React, { useState,useContext } from 'react'

import axios from 'axios';

import { profileContext } from '../../contexts/account'

import Button from '../button';







const CouponModlar = ()=>{
  const [code , setCode] = useState<string>("")
  const { profile,updateProfile } = useContext(profileContext)

  const handleCouponUse = () =>{
    const data ={
      userid:profile.id,
      code:code,
    }
    axios.post('/coupons/use',data)
      .then(resp =>{
        updateProfile(profile)
        alert('Coupon has been used successfully')
    }).catch(err =>{
      console.log(err)
      err.toJSON().status === 403 ? 
      alert('You cant use this coupon anymore')
      : err.toJSON().status === 404 ? 
      alert('Wrong Coupon')
      : alert('Something went wrong , try again later')
      })
  }

  return(
    <div className='flex flex-wrap rounded-lg justify-center flex-row content-start bg-main-b w-96 z-20 text-white p-4'>
      <div className='w-full my-4'>
        <div className='text-xl font-medium'>Add Coupon</div>
      </div>
      <div className='w-full'>
        <input value={code} onChange={(e)=>setCode(e.target.value)} type="text" placeholder="Coupon Code" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
      </div>   
      <Button onClick={handleCouponUse} complete variant={3}>Use</Button>
    </div>
  )
}

export default CouponModlar