import React, { useState,useContext,useEffect } from 'react'

import axios from 'axios';


import { profileContext } from '../../contexts/account'



import { HiExclamation } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';





const EnrollModlar = ({data}:{data:{id:number,price:number,name:string}})=>{
  const { profile,updateProfile } = useContext(profileContext)
  const [ enrolled,setEnrolled ]= useState<boolean>(false)
  const navigate = useNavigate()
  //check if student already enrolled
  useEffect(()=>{
    if(profile.courses.length > 0){
      var enroll = profile.courses.some((course:{id:number,state:number})=>{ 
        return course.id === data.id! && course.state === 1
      });
   setEnrolled(enroll)
    }
  },[profile,data.id])
  const handleEnroll = () =>{
    const datar ={
      type:profile.type,
      courseid:data.id,
      studentid:profile.id,
      costprice:data.price
    }
    axios.post('/course/join',datar)
      .then(resp =>{
        updateProfile(profile)
        alert('You have enrolled into the course successfully')
        navigate('/profile')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong , try again later')
      })
  }

  return(
    <div className="flex items-center min-h-screen px-4 py-8">
      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
        {
          //check if user is logged or not
          profile.id ? 
          //check if student already enrolled
          enrolled ? 
          <div className="mt-3 sm:flex">
              <div className="text-yellow-600 flex items-center justify-center flex-none w-12 h-12 mx-auto bg-yellow-100 rounded-full">
                <HiExclamation className='h-6 w-6' />
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-medium text-gray-800">
                      You have already enrolled
                  </h4>
                  <p className="mt-2 text-sm leading-relaxed text-gray-500">
                    You have already enrolled in this course so please vist the main coures to check it out
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex">
                      <Link to={`/course/${data.id}`} className="text-center w-full mt-2 p-2.5 flex-1 text-white bg-yellow-600  hover:opacity-90 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2">
                          {data.name}
                      </Link>
                  </div>
              </div>
          </div> 
          //check if user have enough money
          : profile.balance < data.price ?
          <div className="mt-3 sm:flex">
              <div className="text-yellow-600 flex items-center justify-center flex-none w-12 h-12 mx-auto bg-yellow-100 rounded-full">
                <HiExclamation className='h-6 w-6' />
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-medium text-gray-800">
                      You dont have enough credit
                  </h4>
                  <p className="mt-2 text-sm leading-relaxed text-gray-500">
                    Your account doesn't have enough credit balance to enroll in the course so please recharge 
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex">
                      <Link to={`/profile`} className="text-center w-full mt-2 p-2.5 flex-1 text-white bg-yellow-600  hover:opacity-90 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2">
                          Recharge
                      </Link>
                  </div>
              </div>
          </div> 

          //check if the user type is a student
          : profile.type === 1 ?
          <div className="mt-3 sm:flex">
              <div className="text-sub-a flex items-center justify-center flex-none w-12 h-12 mx-auto bg-main-b rounded-full">
                <HiExclamation className='h-6 w-6' />
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-medium text-gray-800">
                      Enroll to {data.name} ?
                  </h4>
                  <p className="mt-2 text-sm leading-relaxed text-gray-500">
                      Agreeing to this means you have read all of the course description and agreeing to pay the spefic amount of money 
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex">
                      <button onClick={handleEnroll} className="w-full mt-2 p-2.5 flex-1 text-sub-a bg-main-b  hover:opacity-90 rounded-md outline-none ring-offset-2 ring-sub-a focus:ring-2">
                          Pay ${data.price}.00
                      </button>
                      <button className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                      >
                          Cancel
                      </button>
                  </div>
              </div>
          </div>
          //if not student then show this
          :
          <div className="mt-3 sm:flex">
              <div className="text-yellow-600 flex items-center justify-center flex-none w-12 h-12 mx-auto bg-yellow-100 rounded-full">
                <HiExclamation className='h-6 w-6' />
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-medium text-gray-800">
                      Only Students can enroll to courses
                  </h4>
                  <p className="mt-2 text-sm leading-relaxed text-gray-500">
                    Only verfied students can enroll in these courses
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex">
                      <Link to={'/'} className="text-center w-full mt-2 p-2.5 flex-1 text-white bg-yellow-600  hover:opacity-90 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2">
                          Home
                      </Link>
                  </div>
              </div>
          </div>   
          //if not logged in yet show this       
          : 
          <div className="mt-3 sm:flex">
              <div className="text-red-600 flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                <HiExclamation className='h-6 w-6' />
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-medium text-gray-800">
                      You have not logged in yet!
                  </h4>
                  <p className="mt-2 text-sm leading-relaxed text-gray-500">
                    Only verfied students can enroll in these courses , so please login if you want to enroll in this course
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex">
                      <Link to={'/login'} className="text-center w-full mt-2 p-2.5 flex-1 text-white bg-red-600  hover:opacity-90 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2">
                          Login
                      </Link>
                      <button className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                      >
                          Cancel
                      </button>
                  </div>
              </div>
          </div>
        }
      </div>
    </div>
  )
}

export default EnrollModlar