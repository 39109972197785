import React, { useState,useContext, useEffect,useRef } from 'react'

import { BsThreeDots } from 'react-icons/bs'
import { RiChatFollowUpFill } from 'react-icons/ri'
import { AiFillDelete,AiOutlineArrowLeft } from 'react-icons/ai'
import IconInput from '../components/iconinput'
import { GoSearch } from 'react-icons/go'
import { IoSend } from 'react-icons/io5'


import { messengerContext } from '../contexts/messages'
import { profileContext } from '../contexts/account'
import axios from 'axios'


interface messageProps{
  id:number;
  mfrom:number;
  mto:number;
  message:string;
  isgroup:number;
  seen:string;
  message_time:any;
  user?:{full_name:string,image:string;}
}

interface courseItemsProps{
  id:number;
  name:string;
  material_id:number;
  price:number;
  teacher_id:number;
  startTime:string;
  period:string;
  meetings_number:number;
  description:string;
}

const sqlToJs = (date:string) =>{
  var dateStr=date; //returned from mysql timestamp/datetime field
  var a=dateStr.split("T");
  var d=a[0].split("-");
  var t:any=a[1].split(":");
  var formatedDate = new Date(parseInt(d[0]),(parseInt(d[1])-1),parseInt(d[2]),parseInt(t[0]),parseInt(t[1]),parseInt(t[2]));
  return formatedDate
}


const ChatHead = ({id,handleSelect,msg}:{id:number;handleSelect:Function,msg:messageProps}) =>{
  const [user , setUser] = useState<{full_name:string,image:string;}>({full_name:'',image:'black.jpg'})
  const [seen , setSeen] = useState<boolean>(false)
  const { profile } = useContext(profileContext)
  useEffect(()=>{
    const getData = async () =>{
      const userData = await axios.get(`/getuserdata/${id}`)
      setUser(userData.data)
    }
    getData()
    if(msg){
      const seens = msg.seen.split(",")
      const isSeen = seens.includes(profile.id.toString())
      setSeen(isSeen)
    }    
  },[id,msg,profile.id])
  //handleSelect is used to select this chat as the main chat which will appear , try on mobile view to fully understand
return(
  <div onClick={()=>handleSelect(id)} className='relative group hover:cursor-pointer flex flex-row items-center p-4'>
    <div className='w-2/12'><img src={`https://clashingblades.com/api/teachapi/uploads/${user.image}`} alt='Profile' className='w-12 h-12 rounded-full' /></div>
    <div className={`w-10/12 ${seen ? 'font-normal' : 'font-medium'} overflow-hidden`}>
      <div>{user.full_name}</div>
      <div className='w-full max-h-6' >{msg.mfrom === profile.id ? 'You:' :''}{msg.message}</div>
      </div>
    <div className='flex justify-end items-center text-sub-a absolute z-40 right-0'>
      <button className='invisible opacity-0 group-hover:opacity-100 duration-500 group-hover:visible bg-main-b p-2 mx-2 rounded-full hover:text-sub-b transition-colors'><BsThreeDots /></button>
      <div className={` ${seen ? 'invisible' :'visible'/*When the message not seen there will be a circle to indicate that */ } h-4 w-4 bg-main-b rounded-full`}></div>
    </div>
  </div>
)
}

const CourseChatHead = ({id,handleSelect,msg}:{id:number;handleSelect:Function,msg:messageProps}) =>{
   const [course , setCourse] = useState<{name:string}>({name:''})
   const [material , setMaterial ] = useState<{id:number;name:string;image:string,field:number}>({
    id:0,
    name:'Teach Online',
    image:'black.jpg',
    field:0
  })
   const [seen , setSeen] = useState<boolean>(false)
   const { profile } = useContext(profileContext)
   const [ groupUser , setGroupUser ] = useState({id:0,image:'black.jpg',full_name:'Other'})

  useEffect(()=>{
    const getData = async () =>{
      const getCourse = await axios.get(`/courseid/${id*-1}`)
      const getMaterial = await axios.get(`/material/${getCourse.data.material_id}`)
      const userData = await axios.get(`/getuserdata/${msg.mfrom}`)
      setGroupUser(userData.data)
      setCourse(getCourse.data)
      setMaterial(getMaterial.data)
    }
    getData()
    if(msg.id){
      const seens = msg.seen.split(",")
      const isSeen = seens.includes(profile.id.toString())
      setSeen(isSeen)
    }    
  },[id,msg,profile.id])
  //handleSelect is used to select this chat as the main chat which will appear , try on mobile view to fully understand
return(
  <div onClick={()=>handleSelect(id)} className='relative group hover:cursor-pointer flex flex-row items-center p-4'>
    <div className='w-2/12'><img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt='Profile' className='w-12 h-12 rounded-full' /></div>
    <div className={`w-10/12 ${seen ? 'font-normal' : 'font-medium'} overflow-hidden`}>
      <div>{course.name}</div>
      <div className='w-full max-h-6' >{msg.mfrom === profile.id ? 'You' : groupUser.full_name }:{msg.message}</div>
      </div>
    <div className='flex justify-end items-center text-sub-a absolute z-40 right-0'>
      <button className='invisible opacity-0 group-hover:opacity-100 duration-500 group-hover:visible bg-main-b p-2 mx-2 rounded-full hover:text-sub-b transition-colors'><BsThreeDots /></button>
      <div className={` ${seen ? 'invisible' :'visible'/*When the message not seen there will be a circle to indicate that */ } h-4 w-4 bg-main-b rounded-full`}></div>
    </div>
  </div>
)
}
///Message item
const MessageItem = ({id,mfrom,isgroup,message,seen,message_time,user}:messageProps) =>{
  const { profile } = useContext(profileContext)
  const [ groupUser , setGroupUser ] = useState({id:0,image:'black.jpg'})
   useEffect(()=>{
    const seens =  seen.split(",")
    const isSeen =seens.includes(profile.id.toString())
    if(!isSeen){
      const updatedSeens =  seens[0] ? [...seens,profile.id] : [profile.id]
      axios.post('/message/updateseen',{msgid:id,seens:updatedSeens})
    }
    if(isgroup){
      const getData = async () =>{
        const userData = await axios.get(`/getuserdata/${mfrom}`)
        setGroupUser(userData.data)
      }
      getData()
    }
  },[seen,profile.id,id,isgroup,mfrom])
  return(
    <div className={` ${mfrom !== profile.id ? 'flex-row' : 'flex-row-reverse '} w-full group flex items-center my-1.5 lg:my-2`}>
      {
        isgroup ?
        <div className='w-12 flex justify-center'><img src={`https://clashingblades.com/api/teachapi/uploads/${mfrom !== profile.id ? groupUser?.image : profile.image}`} alt='Profile' className='w-12 h-12 rounded-full' /></div>
        :
        <div className='w-12 flex justify-center'><img src={`https://clashingblades.com/api/teachapi/uploads/${mfrom !== profile.id ? user?.image : profile.image}`} alt='Profile' className='w-12 h-12 rounded-full' /></div>

      }
      <div className={`${mfrom !== profile.id ? 'justify-start' : 'justify-end'} px-4 w-10/12 lg:w-10/12  overflow-hidden flex `}>
        <div className={`${mfrom !== profile.id ? 'bg-sub-b' : 'bg-main-b text-white'} rounded-lg p-2 inline-flex `}>{message}</div>
      </div>

    </div>
  )
  }

const MessagesPage = () =>{
  const [selectedChat , setSelectedChat] = useState({id:0,visible:false})
  const [chats , setChats] = useState<Array<{head:number,msg:messageProps}>>([])
    //handleSelect is used to select this chat as the main chat which will appear , try on mobile view to fully understand
  const { messages,ForceUpdateMessages } = useContext(messengerContext)
  const { profile } = useContext(profileContext)
  const [selectedChatMsgs , setChatMgs] = useState<Array<messageProps>>([])
  const [selectedUser , setSelectedUser] = useState({full_name:'',image:'black.jpg',userid:0})
  const [message , setMessage] = useState<string>("")
  const chatref = useRef<HTMLDivElement | null>(null)
  const handleSelect = (id:number)=>{
    setSelectedChat({id:id,visible:true})
  }
  //When deselect a chat , check mobile to understand fully
  const handleReturn = ()=>{
    setSelectedChat({id:0,visible:false})
  }  
  useEffect(()=>{
    //get chat heads and their last messages 
    //its complicated way so dont bother
    const getData = async () =>{
      const getChats = await axios.post('/message/getchats',{userid:profile.id})
      async function getMessages(head:{mto:number}, index:number){
        const getLastMessage = await axios.post('/message/getlastmessage',{userid:profile.id,otherid:head.mto})
        return await {head:head.mto,msg:{...getLastMessage.data,message_time:sqlToJs(getLastMessage.data.message_time)}};
    }
    async function getCoursesMessages(course:courseItemsProps, index:number){
      const getLastMessage = await axios.post('/message/getlastgroupmessage',{userid:profile.id,otherid:course.id * - 1})
      return await {head:course.id * - 1,msg:getLastMessage.data.id ? {...getLastMessage.data,message_time:sqlToJs(getLastMessage.data.message_time)} : {}};
  }
    let coursesChats = []
      if(profile.courses.length > 0){
        coursesChats = await Promise.all(
          profile.courses.map(getCoursesMessages)
        )
      }       
      const allChats = await Promise.all(
        getChats.data.map(getMessages)
    );
        if(coursesChats.length > 0){
          let array = [...coursesChats,...allChats].sort(function(a,b){
            // order them with their time
            return b.msg.message_time - a.msg.message_time;
          })
          setChats(array)
        }else{
          setChats(allChats.sort(function(a,b){
            // order them with their time
            return b.msg.message_time - a.msg.message_time;
          }))          
        }
    }
    getData()    
  },[profile,messages])

  //get last messages when a chat is selected
  useEffect(()=>{
    const getData = async () =>{
      if(selectedChat.id < 0 ){
        const getLastMessage = await axios.post('/message/getcoursemsgs',{otherid:selectedChat.id})
        setChatMgs(getLastMessage.data)  
        const getCourse = await axios.get(`/courseid/${selectedChat.id*-1}`)
        const getMaterial = await axios.get(`/material/${getCourse.data.material_id}`)  
        setSelectedUser({full_name:getCourse.data.name,image:getMaterial.data.image,userid:selectedChat.id})
  
      }else{
        const getLastMessage = await axios.post('/message/getmsgs',{userid:profile.id,otherid:selectedChat.id})
        setChatMgs(getLastMessage.data)        
        const userData = await axios.get(`/getuserdata/${selectedChat.id}`)
        setSelectedUser(userData.data)
  
      }
    }
    if(selectedChat.id){
      getData()

    }

  },[selectedChat,profile.id,messages])
  useEffect(()=>{
    if(selectedChat.id){
      if(selectedChatMsgs){
        chatref.current!.scrollTop = chatref.current!.scrollHeight
      }
    }
  },[selectedChat.id,selectedChatMsgs])
  const handleMessage = () =>{
    const datar ={
      message:message,
      from:profile.id,
      to:selectedUser.userid,
      isgroup:selectedUser.userid < 0 ? 1 : 0,
    }
    axios.post('/message/send',datar)
      .then(resp =>{
        ForceUpdateMessages()
        setMessage("")
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong , try again later')
      })
  }
  return(
    <section className='h-screen flex flex-row overflow-hidden'>
      <div className={`lg:flex ${selectedChat.visible ? 'hidden' : 'flex' } w-full lg:w-4/12 flex-col  border-r-2 border-sub-a`}>
        <div className='flex flex-row items-center p-4'>
          <div className='w-2/12'><img src={`https://clashingblades.com/api/teachapi/uploads/${profile.image}`} alt='Profile' className='w-12 h-12 rounded-full' /></div>
          <div className='w-3/12 font-bold text-2xl text-center '>Chats</div>
          <div className='w-7/12 flex justify-end items-center text-sub-a'>
            <button className='bg-main-b p-2 mx-2 rounded-full hover:text-sub-b transition-colors'><BsThreeDots /></button>
            <button className='bg-main-b p-2 mx-2 rounded-full hover:text-sub-b transition-colors'><RiChatFollowUpFill /></button>
            <button className='bg-main-b p-2 mx-2 rounded-full hover:text-sub-b transition-colors'><AiFillDelete /></button>
          </div>
        </div>
        <div className='flex justify-center items-center px-4 lg:px-6'>
          <IconInput className='rounded-full border-main-b text-main-b' type="text" placeholder='Seach for ....' icon={GoSearch} iconClass="text-main-b text-xl " />         
        </div>
        <div className='scroll h-full overflow-y-scroll  '>
          {
            chats.length > 0 ?
            
            chats.map((chat)=>(
              chat.head !== profile.id ?
              chat.head < 0 ?
              <CourseChatHead key={chat.head} handleSelect={handleSelect} id={chat.head} msg={chat.msg} />
              :
              <ChatHead key={chat.head} handleSelect={handleSelect} id={chat.head} msg={chat.msg} />
              : null
            )) 
            : 
            <div className='flex justify-center pt-16'>
              You have not chatted with anyone yet
            </div>
          }
        </div>
      </div>
      {
        selectedChat.id ? 
        <div className={`${selectedChat.visible ? 'flex' : 'hidden' } w-full lg:w-8/12 lg:flex flex-col `}>
        <div className='flex flex-row items-center p-4 border-b border-sub-a'>
            <div className='lg:hidden w-1/12 flex justify-end items-center text-main-b'>
              <button onClick={handleReturn} className='text-2xl p-2 mx-2 rounded-full hover:text-sub-b transition-colors'><AiOutlineArrowLeft /></button>
            </div>          
            <div className='w-2/12 lg:w-1/12'><img src={`https://clashingblades.com/api/teachapi/uploads/${selectedUser.image}`} alt='Profile' className='w-12 h-12 rounded-full' /></div>
            <div className='w-6/12 lg:w-3/12 font-medium text-xl overflow-hidden '>{selectedUser.full_name}</div>
            <div className='w-3/12 lg:w-8/12 flex justify-center lg:justify-end items-center text-sub-a'>
              <button className='bg-main-b p-2 mx-1 lg:mx-2 rounded-full hover:text-sub-b transition-colors'><AiFillDelete /></button>
              <button className='bg-main-b p-2 mx-1 lg:mx-2 rounded-full hover:text-sub-b transition-colors'><BsThreeDots /></button>
            </div>
        </div>
        <div ref={chatref} className='h-full overflow-y-scroll'>
          <div className='flex flex-row flex-wrap-reverse'>
            {
              selectedChatMsgs.map((msg:messageProps)=>
              <MessageItem key={msg.id} {...msg} user={selectedUser} />
              )
            }               
          </div>

        </div>
        <div  className='flex justify-center items-center px-6 border-t py-4 border-sub-a'>
          <input onKeyDown={(e)=>e.key=== 'Enter' ? handleMessage() : null } value={message} onChange={(e)=>setMessage(e.target.value)} className='border pl-4 py-2 w-full rounded-full border-main-b text-main-b' type="text" placeholder='Aa' /> 
          <IoSend onClick={handleMessage} className='h-6 w-6 text-main-b hover:cursor-pointer ml-4' />        
        </div>
        </div>

        : 
        <div className='lg:flex hidden bg-main-b h-full lg:w-8/12 text-sub-a text-4xl font-serif font-bold justify-center items-center'>
          Chat with any one now!
        </div>

      }
    </section>
  )
}



export default MessagesPage