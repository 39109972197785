import React from 'react'


import ProfileModlar from './profile'
import CouponModlar from './coupon'
import CourseModlar from './course'
import { AdminUserModlar , AdminFieldModlar , AdminMaterialModlar , AdminCouponModlar } from './admin'
import EnrollModlar from './enrolls'
import MessageModlar from './message'
import PrivateModlar from './private'

const Modlar =({type, active,editModal,data,getData}:{getData?:Function,type:number,active:boolean,editModal:Function,data?:any}) => {


  //if only is active it will be shown to aviod any bugs
  return active ? (
  <div className={`${active ? "fixed opacity-100 visible" : "invisible"} modlar inset-0 overflow-y-auto z-40 flex justify-center items-center`}>
    <div className="fixed inset-0 w-full h-full bg-black opacity-70 -z-10" onClick={() => editModal({type:type,active:false})/*to turn it off when clicked outside the area */}></div>
      {
        // its like a switch case , when type 1 is selected it will show and so on
        type === 1 ? (<ProfileModlar />)
      : type === 2 ? (<CouponModlar />)
      : type === 3 ? (<AdminMaterialModlar getData={getData? getData : editModal} data={data} />)
      : type === 4 ? (<AdminFieldModlar getData={getData? getData : editModal} data={data} />)
      : type === 5 ? (<AdminUserModlar getData={getData? getData : editModal} data={data} />)
      : type === 6 ? (<AdminCouponModlar getData={getData? getData : editModal} data={data} />)
      : type === 7 ? (<CourseModlar data={data}  />)
      : type === 8 ? (<EnrollModlar data={data}  />)
      : type === 9 ? (<MessageModlar data={data} editModal={editModal}  />)
      : type === 10 ? (<PrivateModlar data={data}  />)
      : null
      }
  </div>
  ): null
}



export default Modlar