import axios from 'axios';
import { createContext , useCallback } from 'react';


import { useLocalStorage } from '../hooks/useLocalStorage'
import { isObjectEqual } from '../hooks/Utils'


export const profileContext = createContext();

export default function Profile({ children }) {
  const [profile, setProfile] = useLocalStorage("profile", {id:0,email:'',type:0,courses:[],rating:0})


  const studentProfile = useCallback(
    async (user)=>{
      //get student data
      const balance = await axios.get(`/userbalance/${user.id}`)
      const studentData = await axios.get(`/student/${user.id}`)
      const coursesData = await axios.get(`/studentcourses/${user.id}`)
      const getRating = await axios.get(`/rate/getrate/${user.id}`)
      //spreading all user and student properites 
      const newUser = {...user,...studentData.data,...balance.data,courses:coursesData.data ? coursesData.data : [],rating:getRating.data ? getRating.data/2 : 0}
      const check = () =>{
        try{
          if(!isObjectEqual({...user,courses:0},{...newUser,courses:0})){
            return false
          }
          if(user.courses){
            if(user.courses.length !== coursesData.data.length ){
              return false
            }
            if(user.courses.length > 0){
              const courseCheck = user.courses.some((c,index)=>{
                return !isObjectEqual(user.courses[index],coursesData.data[index])
              })
              if(courseCheck){
                return false
              }
            }
            
          }
          return true
        }catch(err){
          setProfile(newUser)
        }
      }
      const update = check() 
      if(!update){
        setProfile(newUser)
      }

    }
  ,[setProfile])
  
  const teacherProfile = useCallback(
    async (user)=>{
      //get teacher data
      const balance = await axios.get(`/userbalance/${user.id}`)
      const teacherData = await axios.get(`/teacher/${user.id}`)
      const FieldData = await axios.get(`/fields/${teacherData.data.field}`)
      const coursesData = await axios.get(`/coursesbyid/${user.id}`)
      const getRating = await axios.get(`/rate/getrate/${user.id}`)
      //spreading all user and teacher properites 
      const newUser ={...user,...teacherData.data,...balance.data,field_name:FieldData.data.name,courses:coursesData.data ? coursesData.data : [],rating:getRating.data ? getRating.data/2 : 0}
      const check = () =>{
        try{
          if(!isObjectEqual({...user,courses:0},{...newUser,courses:0})){
            return false
          }
          if(user.courses){
            if(user.courses.length !== coursesData.data.length ){
              return false
            }
            if(user.courses.length > 0){
              const courseCheck = user.courses.some((c,index)=>{
                return !isObjectEqual(user.courses[index],coursesData.data[index])
              })
              if(courseCheck){
                return false
              }
            }
            
          }
          return true
        }catch(err){
          setProfile(newUser)
        }
      }
      const update = check() 
      if(!update){
        setProfile(newUser)
      }
    }
  ,[setProfile])
  
  const  updateProfile = useCallback(
    (user) =>{
      if(user.type===1){
        studentProfile(user)
      }else{
        teacherProfile(user)
  
      }
    }    
  ,[studentProfile,teacherProfile])



  const logOut = useCallback(
    ()=>{
      setProfile({id:0,email:'',type:0,courses:[]})
    }
  ,[setProfile])

  return (
    <profileContext.Provider value = {{profile,updateProfile,logOut}}>
      {children}  
    </profileContext.Provider>
  );
}

