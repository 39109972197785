import React, { useState,useContext,useEffect } from 'react'

import axios from 'axios';

import { FiImage } from 'react-icons/fi';

import { profileContext } from '../../contexts/account'

import Button from '../button';
import { MdKeyboardArrowDown } from 'react-icons/md';




export const AdminUserModlar = ({data,getData}:{data:any,getData:Function})=>{
  const [email , setEmail] = useState<string>("")
  const [balance , setBalance] = useState<number>(0)
  const [type , setType] = useState<number>(1)
  const [password , setPassword] = useState<string>("")
  const { profile } = useContext(profileContext)
  useEffect(()=>{
    //check if its edit data not adding , if there some data it will be added to inputs
    if(data.email){
      setEmail(data.email)
      setBalance(data.balance)
      setType(data.type)
    }
  },[data])

  const handleAdd = () =>{
    const sentdata ={
      password:password,
      email:email,
      utype:type,
      balance:balance,
      type:profile.type,
    }
    axios.post('/admin/adduser',sentdata)
      .then(resp =>{
        getData()
        alert('user has been added successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }

  const handleEdit = () =>{
    const sentdata ={
      userid:data.id,
      password:password,
      email:email,
      utype:type,
      balance:balance,
      type:profile.type,
    }
    axios.post('/admin/edituser',sentdata)
      .then(resp =>{
        getData()
        alert('user has been edited successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }
  return(
    <div className='flex flex-wrap rounded-lg justify-center flex-row content-start bg-main-b w-96 z-20 text-white p-4'>
      <div className='w-full my-4'>
        <div className='text-xl font-medium'>{data.id ? "Edit" : "Add"} User</div>
      </div>
      <div className='w-full'>
        <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Email" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
        <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="Password" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
        <input value={balance} onChange={(e)=>setBalance(parseInt(e.target.value))} type="number" placeholder="Balance" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
        <div className="relative w-full mb-4 ">
          <span className='absolute text-main-b top-3 right-2 text-2xl'>
            <MdKeyboardArrowDown />
          </span>
          <select  value={type} onChange={(e)=>setType(parseInt(e.target.value))}  className="w-full p-2.5 rounded-lg text-gray-500 bg-white border outline-none appearance-none ">
              <option value="" disabled >User Type</option>
              <option value={1} selected>Student</option>
              <option value={2}>Teacher</option>
              <option value={27}>Admin</option>
          </select>
        </div>

      </div>
      {
        data.id ? (
          <Button onClick={handleEdit} complete variant={3}>Edit</Button>
        ):(
          <Button onClick={handleAdd} complete variant={3}>Add</Button>
        )
      }   
    </div>
  )
}

export const AdminFieldModlar = ({data,getData}:{data:any,getData:Function})=>{
  const [name , setName] = useState<string>("")

  const { profile } = useContext(profileContext)
  useEffect(()=>{
    //check if its edit data not adding , if there some data it will be added to inputs
    if(data.name){
      setName(data.name)
    }
  },[data])

  const handleAdd = () =>{
    const sentdata ={
      name:name,
      type:profile.type,
    }
    axios.post('/admin/addfield',sentdata)
      .then(resp =>{
        getData()
        alert('Field has been added successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }

  const handleEdit = () =>{
    const sentdata ={
      id:data.id,
      name:name,
      type:profile.type,
    }
    axios.post('/admin/editfield',sentdata)
      .then(resp =>{
        getData()
        alert('Field has been edited successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }
  return(
    <div className='flex flex-wrap rounded-lg justify-center flex-row content-start bg-main-b w-96 z-20 text-white p-4'>
      <div className='w-full my-4'>
        <div className='text-xl font-medium'>{data.id ? "Edit" : "Add"} Field</div>
      </div>
      <div className='w-full'>
        <input value={name} onChange={(e)=>setName(e.target.value)} type="text" placeholder="Field Name" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
      </div>
      {
        data.id ? (
          <Button onClick={handleEdit} complete variant={3}>Edit</Button>
        ):(
          <Button onClick={handleAdd} complete variant={3}>Add</Button>
        )
      }   
    </div>
  )
}

export const AdminMaterialModlar = ({data,getData}:{data:any,getData:Function})=>{
  const [name , setName] = useState<string>("")
  const [image,setImage] = useState<{data:any , preview:string}>({data:null , preview:`https://dummyimage.com/400x400/4e2e6d/fff`})
  const [field , setField] = useState<number>(1)
  const [fieldsdata , setFieldsData] = useState<Array<{id:number,name:string}>>([])
  const { profile } = useContext(profileContext)
  useEffect(()=>{
    const getData = async () =>{
      const getFields = await axios.get("/fields")
      setFieldsData(getFields.data)
    }
    getData()
    //check if its edit data not adding , if there some data it will be added to inputs
    if(data.name){
      setName(data.name)
      setImage({data:null,preview:`https://clashingblades.com/api/teachapi/uploads/${data.image}`})
      setField(data.field)
    }
  },[data])

  const handleImageSelect = (e:React.ChangeEvent<HTMLInputElement>) =>{
    //this is used when a select image button is clicked on 
    //if exists to aviod if users doesnt select an image
    if(e.target.files){
      setImage({data:e.target.files[0],preview:URL.createObjectURL(e.target.files[0])})
    }
  }

  const handleAdd = () =>{
    const Data = new FormData()
    Data.append('name' , name)      
    Data.append('field' , field.toString())                
    Data.append('imgfile', image.data)
    Data.append('type', profile.type)
    axios.post('/admin/addmaterial',Data)
      .then(resp =>{
        getData()
        alert('Material has been added successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }

  const handleEdit = () =>{
    const Data = new FormData()
    Data.append('id' , data.id)     
    Data.append('name' , name)      
    Data.append('field' , field.toString())                
    Data.append('imgfile', image.data)
    Data.append('type', profile.type)
    axios.post('/admin/editmaterial',Data)
      .then(resp =>{
        getData()
        alert('Material has been edited successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }
  return(
    <div className='flex flex-wrap rounded-lg justify-center flex-row content-start bg-main-b w-96 z-20 text-white p-4'>
      <div className='w-full my-4'>
        <div className='text-xl font-medium'>{data.id ? "Edit" : "Add"} Material</div>
      </div>
      <div className='w-full'>
        <input value={name} onChange={(e)=>setName(e.target.value)} type="text" placeholder="Field Name" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
      </div>
      <div className="relative w-full mb-4 ">
        <span className='absolute text-main-b top-3 right-2 text-2xl'>
          <MdKeyboardArrowDown />
        </span>
        <select  value={field} onChange={(e)=>setField(parseInt(e.target.value))}  className="w-full p-2.5 rounded-lg text-gray-500 bg-white border outline-none appearance-none ">
            {
              fieldsdata.length > 0 ? 
              fieldsdata.map(field=>(
                <option key={field.id} value={field.id} selected>{field.name}</option>
              ))
              : null
            }
        </select>
      </div>
      <div className='h-64 w-full rounded-lg mb-8'>
        <img src={image.preview} alt='upload img' className='w-full h-full rounded-lg' />
      </div>
      <label className="w-5/12  mx-2 flex flex-col items-center py-2 bg-sub-a text-main-b rounded-lg shadow-lg tracking-wide uppercase border-2 border-transparent cursor-pointer hover:bg-main-b hover:text-sub-a hover:border-sub-a">
          <FiImage className='h-8 w-8' />
          <input type='file' className="hidden" onChange={handleImageSelect} />
      </label>     
      {
        data.id ? (
          <Button onClick={handleEdit} complete variant={3}>Edit</Button>
        ):(
          <Button onClick={handleAdd} complete variant={3}>Add</Button>
        )
      }   
    </div>
  )
}


export const AdminCouponModlar = ({data,getData}:{data:any,getData:Function})=>{
  const [code , setCode] = useState<string>("")
  const [uses , setUses] = useState<number>()
  const [value , setValue] = useState<number>()

  const { profile } = useContext(profileContext)
  useEffect(()=>{
    //check if its edit data not adding , if there some data it will be added to inputs
    if(data.code){
      setCode(data.code)
      setUses(data.uses)
      setValue(data.value)
    }
  },[data])

  const handleAdd = () =>{
    const sentdata ={
      uses:uses,
      value:value,
      type:profile.type,
    }
    axios.post('/admin/addcoupon',sentdata)
      .then(resp =>{
        getData()
        alert('Coupon has been added successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }

  const handleEdit = () =>{
    const sentdata ={
      id:data.id,
      uses:uses,
      value:value,
      type:profile.type,
    }
    axios.post('/admin/editcoupon',sentdata)
      .then(resp =>{
        getData()
        alert('Coupon has been edited successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }
  return(
    <div className='flex flex-wrap rounded-lg justify-center flex-row content-start bg-main-b w-96 z-20 text-white p-4'>
      <div className='w-full my-4'>
        <div className='text-xl font-medium'>{data.id ? "Edit" : "Add"} Coupon</div>
      </div>
      <div className='w-full'>
        <input value={code} disabled type="text" placeholder="Coupon Code" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
      </div>
      <div className='w-full'>
        <input value={uses} onChange={(e)=>setUses(parseInt(e.target.value))} type="number" placeholder="Coupon Uses" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
      </div>
      <div className='w-full'>
        <input value={value} onChange={(e)=>setValue(parseInt(e.target.value))} type="number" placeholder="Coupon Value" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
      </div>      
      {
        data.id ? (
          <Button onClick={handleEdit} complete variant={3}>Edit</Button>
        ):(
          <Button onClick={handleAdd} complete variant={3}>Add</Button>
        )
      }   
    </div>
  )
}