import { useEffect,useContext} from 'react'

import { Link } from 'react-router-dom';

import { IconType } from 'react-icons/lib';
import { FaFacebookF,FaDiscord } from "react-icons/fa"
import { BsYoutube,BsTwitter,BsTelegram } from "react-icons/bs"

import Button from './button'

import { messengerContext } from '../contexts/messages'
import { profileContext } from '../contexts/account';


const socialinks=[
  {id:1,link:'https://www.facebook.com/',icon:FaFacebookF},
  {id:2,link:'https://www.youtube.com/',icon:BsYoutube},
  {id:3,link:'https://www.twitter.com/',icon:BsTwitter},
  {id:4,link:'https://www.telegram.com/',icon:BsTelegram},
  {id:5,link:'https://www.discord.com/',icon:FaDiscord}
]


const FootLink = ({name,to}:{name:string,to:string}) =>{
  return(
    <div className='px-2 text-main-a my-1 first:pl-0 border-r-2 border-main-b last:border-r-0 md:text-sm text-xs'>
      <Link to={to}>
        {name}    
      </Link>
    </div>
  )
}

const SocialLink = (props:{link:string,icon:IconType}) =>{
  return(
    <div className='text-xl mx-2 text-main-b'>
      <a href={props.link}>
        <props.icon />
      </a>
    </div>
  )
}


const Footer = () =>{
 const { messages,updateMessages } = useContext(messengerContext)
 const { profile } = useContext(profileContext)
    //refresh messages every 1 second
    useEffect(()=>{
      const interval = setInterval(()=>{
       updateMessages()
     },1000)
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[messages,profile])
  return(
    <footer className='px-4 py-2 bg-sub-a flex flex-wrap overflow-hidden md:px-12 md:py-8'>
      <div className="w-full md:w-1/2 overflow-hidden self-start flex flex-wrap md:justify-start">
        <FootLink name="Home" to="/" />
        <FootLink name="Courses" to="/courses" />
        <FootLink name="Teachers" to="/teachers" />
        <FootLink name="About" to="/about" />
        <FootLink name="Profile" to="/profile" />
        <FootLink name="Sign Up" to="/signup" />
        <FootLink name="Login" to="/login" />
      </div>

      <div className="w-full md:w-1/2 min-h-8 overflow-hidden flex md:justify-end justify-start border-t border-sub-b pt-4 mt-2 md:border-t-0 md:pt-0 md:mt-0 border-opacity-40">
        {
          socialinks.map((link)=>(
            <SocialLink link={link.link} icon={link.icon} key={link.id} />
          ))
        }
      </div>
      <div className="w-8/12 text-main-a md:w-1/2 overflow-hidden md:border-t border-main-b mt-4 md:pt-8 pt-2">
        <img src="/assets/logo.png" alt="Clashingblades" width={180} height={60} />
        <div className='font-medium'>© 2022 All rights reserved.</div>
      </div>
      <div className="w-4/12 self-center lg:self-auto md:w-1/2 overflow-hidden md:border-t flex md:justify-end justify-start border-main-b md:mt-4 md:pt-8 mt-2 pt:2">
      <Link to={'/signup'}>
        <Button variant={2} complete className="h-12">
          REGISTER
        </Button>
      </Link>
      </div>

    </footer>
  )
}


export default Footer