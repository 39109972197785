import { useState }  from 'react';

import { Routes, Route } from "react-router-dom";

//Pages
import HomePage from './pages/homepage';// "/"
import AboutPage from './pages/aboutpage';// "/about"
import LoginPage from  './pages/loginpage';// "/login"
import SignupPage from './pages/signuppage'// "/signup"
import CouresPage from './pages/courses';// "/courses"
import CourseProfilePage from './pages/courses/profile';// "/courses/:id"
import TeachersPage from './pages/teachers';// "/teachers"
import TeacherProfilePage from './pages/teachers/profile'; // "/teacher/:id"
import MaterialsPage from './pages/materials';// "//materials"
import MaterialProfilePage from './pages/materials/profile'; /// "/material/:id"
import ProfilePage from './pages/profilepage'; // "/profile"
import StudentProfilePage from './pages/studentpage'; // "/student/:id"
import MessagesPage from './pages/messagespage'; // "messages"
import AdminPage from './pages/adminpage'; // "/admin"
import PrivateCourseProfilePage from './pages/privatecoursepage';
import PrivateMaterialProfilePage from './pages/materials/private';


//Components
import Navbar from './components/navbar';
import Footer from './components/footer';


//app wrapper is a context controller which allow me to use all context together and wrap the main with only it
import { AppWrapper } from './contexts/main';


import axios from "axios";
//change axios url
axios.defaults.baseURL = process.env.NODE_ENV === "development" 
? "https://clashingblades.com/api/teachapi" 
: "https://clashingblades.com/api/teachapi"
axios.defaults.withCredentials = false;



function App() {
  const [email ,setEmail] = useState<string>("") //This is used to pass email from homepage to signupage
  return (
    //i wrapped everything with AppWrapper to allow me use context api(share state with all components)
    <AppWrapper>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage setEmail={setEmail} />} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage email={email} setEmail={setEmail} />} />
        <Route path="/courses" element={<CouresPage />} />
        <Route path="/course/:id" element={<CourseProfilePage />} />
        <Route path="/teachers" element={<TeachersPage />} />
        <Route path="/teacher/:id" element={<TeacherProfilePage />} />
        <Route path="/materials" element={<MaterialsPage/> } />
        <Route path="/material/:id" element={<MaterialProfilePage/> } />    
        <Route path="/profile" element={<ProfilePage/> } />       
        <Route path="/student/:id" element={<StudentProfilePage /> } />   
        <Route path='/messages' element={<MessagesPage/>} />        
        <Route path='/admin' element={<AdminPage />} />          
        <Route path="/privatecourse/:id" element={<PrivateCourseProfilePage />} />
        <Route path="/material/private/:id" element={<PrivateMaterialProfilePage />} />

      </Routes>
      <Footer/>      
    </AppWrapper>    
  )
}

export default App;
