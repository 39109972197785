import React from 'react'

import { useNavigate } from 'react-router-dom'


import Button from '../components/button'



const HomePage = ({setEmail}:{setEmail:Function}) =>{
  const navigate = useNavigate();//this is used to navigate programmatically
  return(
    <section className='min-h-screen w-full py-8 flex justify-center flex-wrap flex-row '>
      <div className='w-full flex-col-reverse 2xl:max-w-screen-2xl xl:max-w-screen-xl flex flex-wrap lg:flex-row'>
        <div className='w-full lg:w-1/2 px-6 lg:px-12'>
          <div className='text-4xl xl:text-7xl 2xl:text-8xl font-light'>
            Online <span className='font-normal text-main-b'>classes from the best</span> instructors 
          </div>
          <div className='px-6 lg:px-24 my-8'>
            <div className='mb-4 font-medium'>Introduction</div>
            <div>Get rid of the rotuine feel from your learning process and replace lame methods with fun engaging ones</div>
          </div>
          <div className='flex'>
            <input
                type="text"
                className="form-control block w-full px-4 rounded-r-none py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Your Email address"
                onChange={(e)=>setEmail(e.target.value)}
              />
            <Button onClick={()=>navigate("/signup")} variant={2} complete className='w-48 rounded-l-none'>Sign Up</Button>
          </div>
        </div>
        <div className='w-full lg:w-1/2 flex justify-center '>
          <img src={'/assets/main.jpg'} className="xl:h-full " alt="main" />
        </div>

      </div>

    </section>
  )
}


export default HomePage