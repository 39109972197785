import React,{ useState,useEffect,useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { AiFillBook } from 'react-icons/ai'
import { BsFillPeopleFill } from 'react-icons/bs'
import { FaBook } from 'react-icons/fa'
import { MdPayments } from 'react-icons/md'
import { RiCoupon2Fill } from 'react-icons/ri'


import Button from '../components/button'
import DataShow from '../components/datashow'

import { profileContext } from '../contexts/account'



const AdminPage = () =>{
  const [ nav , setNav] = useState<number>(0)//its used for navigation between left navbar
  const [ modal , setModal] = useState<boolean>(false) // used to open Modal when it needed
  const { profile } = useContext(profileContext)
  const navigate =useNavigate()
  useEffect(()=>{
    //check if user is admin else send him back to home screen
    if(profile.type !== 27){
      navigate('/')
    }
  },[profile,navigate])
  return profile.type === 27 ?(
    <section className=' flex min-h-screen flex-row content-none lg:content-none flex-wrap'>
      <div className="w-full lg:w-3/12 shadow-md bg-sub-a border-t border-b lg:min-h-screen ">
        <div className="pt-4 pb-2 px-6">
          <div className="flex items-center">
            <div className="shrink-0">
              <img src={`https://clashingblades.com/api/teachapi/uploads/${profile.image}`} className="rounded-full w-10" alt="Avatar" />
            </div>
            <div className="grow ml-3">
              <p className="text-sm font-semibold ">{profile.full_name}</p>
            </div>
          </div>
        </div>
        <ul className="relative px-1">
          <li className="relative group px-4 text-gray-500">
            <button onClick={()=>setNav(0)} className={`${nav === 0 ? 'bg-main-b text-sub-a ' : 'bg-sub-a'} flex w-full rounded-lg text-2xl items-center py-3 px-6 overflow-hidden group-hover:text-main-a`}>
              <MdPayments className={`${nav === 0 ? ' text-sub-a' : ''} mr-6`} />
              <span >Payments</span>
            </button>
          </li>        
          <li className="relative group px-4 text-gray-500">
            <button onClick={()=>setNav(1)} className={`${nav === 1 ? 'bg-main-b text-sub-a ' : 'bg-sub-a'} flex w-full rounded-lg text-2xl items-center py-3 px-6 overflow-hidden group-hover:text-main-a`}>
              <AiFillBook className={`${nav === 1 ? ' text-sub-a' : ''} mr-6`} />
              <span>Materials</span>
            </button>
          </li>
          <li className="relative group px-4 text-gray-500">
            <button onClick={()=>setNav(2)} className={`${nav === 2 ? 'bg-main-b text-sub-a ' : 'bg-sub-a'} flex w-full rounded-lg text-2xl items-center py-3 px-6 overflow-hidden group-hover:text-main-a`}>
              <FaBook className={`${nav === 2 ? ' text-sub-a' : ''} mr-6`} />
              <span>Education Fields</span>
            </button>
          </li>
          <li className="relative group px-4 text-gray-500">
            <button onClick={()=>setNav(3)} className={`${nav === 3 ? 'bg-main-b text-sub-a ' : 'bg-sub-a'} flex w-full rounded-lg text-2xl items-center py-3 px-6 overflow-hidden group-hover:text-main-a`}>
              <BsFillPeopleFill className={`${nav === 3 ? ' text-sub-a' : ''} mr-6`} />
              <span>Users</span>
            </button>
          </li>    
          <li className="relative group px-4 text-gray-500">
            <button onClick={()=>setNav(4)} className={`${nav === 4 ? 'bg-main-b text-sub-a ' : 'bg-sub-a'} flex w-full rounded-lg text-2xl items-center py-3 px-6 overflow-hidden group-hover:text-main-a`}>
              <RiCoupon2Fill className={`${nav === 4 ? ' text-sub-a' : ''} mr-6`} />
              <span>Coupons</span>
            </button>
          </li>                 
        </ul>

      </div>
      <div className='w-full lg:w-9/12 '>
        {
          nav === 0 ? (//If Payments is selected
            <div className='flex py-6 px-8 flex-row flex-wrap justify-around'>
              <div className='flex justify-center my-4 text-center content-center text-sub-a items-center flex-wrap h-72 w-96 rounded-md bg-main-b'>
                <div className='w-full text-6xl'>${4000}</div>
                <div className='w-full text-4xl my-4'>Total</div>
              </div>
              <div className='flex justify-center my-4 text-center content-center text-sub-a items-center flex-wrap h-72 w-96 rounded-md bg-main-b'>
                <div className='w-full text-6xl'>-${3000}</div>
                <div className='w-full text-4xl my-4'>Teacher's Payments</div>
              </div>
              <div className='flex justify-center my-4 text-center content-center text-sub-a items-center flex-wrap h-72 w-96 rounded-md bg-main-b'>
                <div className='w-full text-6xl'>-${600}</div>
                <div className='w-full text-4xl my-4'>Refund</div>
              </div>
              <div className='flex justify-center my-4 text-center content-center text-sub-a items-center flex-wrap h-72 w-96 rounded-md bg-main-b'>
                <div className='w-full text-6xl'>${400}</div>
                <div className='w-full text-4xl my-4'>Profit</div>
              </div>                                          
            </div>
          )
          : nav === 1 ? (//If Materials is selected
            <DataShow type={1} />
          )
          : nav === 2 ? (//If Education Fields is selected
            <DataShow type={2} />
          )
          : nav === 3 ? (//If Users is selected
            <DataShow type={3} />
          )       
          : (//If Users is selected
            <DataShow type={4} />
          )                        
        }

      </div>
      <div className={`${modal ? "fixed" : "hidden"} inset-0 overflow-y-auto z-40 flex justify-center items-center`}>
        <div className="fixed inset-0 w-full h-full bg-black opacity-70 -z-10" onClick={()=>setModal(false)}></div>
        <div className='flex flex-wrap rounded-lg flex-row content-start bg-main-b w-96 z-20 text-white p-4'>
          <div className='w-full my-4'>
            <div className='text-xl font-medium'>Add Something</div>
            <div>Paragraph about something becasue its the most op op thing</div>
          </div>
          <div className='w-full'>
            <input type="text" placeholder="Abcd" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
            <input type="text" placeholder="Bcde" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
            <input type="text" placeholder="Cdef" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
            <input type="text" placeholder="Defg" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />

          </div>
          <div className='w-full text-center'>
            <Button variant={3} complete>Add</Button>          
          </div>                               

        </div>
      </div>
    </section>
  ) : (
    <div>
      Loading
    </div>
  )
}



export default AdminPage