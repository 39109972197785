import React,{ useEffect, useState } from 'react'

import axios from 'axios'

import IconInput from '../../components/iconinput'
import Button from '../../components/button'

import { GoSearch } from 'react-icons/go'


import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation,Autoplay } from "swiper";

interface materialItemProps {
  id:number;
  name:string;
  image:string;
  field:number
}


//This is the course item which is displayed with every course item
const FieldItem = ({id,name}:{id:number;name:string;}) =>{
  const [materials , setMaterials ] = useState<Array<materialItemProps>>([])
  
  useEffect(()=>{
    //get all materials by their field when page first initialized  
    const getData = async () =>{
      try{
        const getMaterials = await axios.get(`/materialsfield/${id}`)
        setMaterials(getMaterials.data)
      }catch(err){
        console.log(err)
      }
    }
    getData()
    },[id])
  return(
    <div className='w-full px-8 my-6 lg:p-4 text-center'>
      <div className='font-bold text-4xl text-main-b'>{name}</div>
      <Swiper className="h-96 w-full mt-4" breakpoints={{
          640: {slidesPerView: 2,spaceBetween: 20,},
          1200: {slidesPerView: 3,spaceBetween: 50,},
          1500: {slidesPerView: 3,spaceBetween: 50,},          
        }} loop navigation={true} spaceBetween={30} autoplay={{delay: 2500,disableOnInteraction: false,}}
          modules={[Navigation,Autoplay]} >
          {
              materials.length > 1 ?
              materials.map(material=>(
                <SwiperSlide key={material.id} className=" bg-main-b rounded-lg flex flex-row flex-wrap justify-center items-center content-center">
                  <Link to={`/material/${material.id}`} className='flex justify-center text-sub-a h-16 w-16'>
                    <img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt="material logo" className='h-full w-full' />
                  </Link>
                  <Link  to={`/material/${material.id}`} className='w-full text-3xl text-sub-a my-6'>{material.name}</Link>
                  <div>
                    <Link to={`/material/private/${material.id}`}>
                      <Button variant={3} complete className='my-6' >Private</Button>                  
                    </Link>
                  </div>
                </SwiperSlide>
              ))
              : null
            }
        </Swiper>  
    </div>
  )
}

const MaterialsPage = ()=>{
  const [ fields , setFields ] = useState<Array<{id:number;name:string;}>>([])
  const [ materials , setMaterials ] = useState<Array<materialItemProps>>([])
  const [search , setSearch] = useState<string>("")
  const [isSearching , setIsSearching] = useState<boolean>(false)
  useEffect(()=>{
  //get all fields when page first initialized  
  const getData = async () =>{
    try{
      const getFields = await axios.get(`/fields`)
      setFields(getFields.data)
    }catch(err){
      console.log(err)
    }
  }
  getData()

  },[])

  const handleSearch = async (e:React.ChangeEvent<HTMLInputElement>) =>{
    //this is used to enable search mode when there is a search
    const text = e.target.value
    setSearch(text)
    if(text.length > 0 ){
      const getMaterials = await axios.get(`/materials/searching/${text}`)
      setMaterials(getMaterials.data)
      setIsSearching(true)
    }else{
      setIsSearching(false)
    }
  }


  return(
    <section>
      <div className='courses h-96 flex flex-wrap flex-row justify-center content-center items-center text-center px-4 lg:px-12' style={{backgroundImage: 'url("/assets/courses_header.svg")'}}>
        <div className='w-full text-6xl font-medium mb-4'>Materials</div>
        <div className='text-xl max-w-2xl mt-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod autem error quisquam unde aspernatur delectus totam adipisci.</div>
      </div>
      <div className='min-h-screen bg-white'>
        <div className='w-full bg-main-b flex px-8 justify-center py-6'>
          <div className='w-10/12 lg:w-5/12 flex justify-center items-center'>
            <IconInput value={search} onChange={handleSearch} type="text" placeholder='Search for a material ....' icon={GoSearch} iconClass="text-main-b text-xl" />         
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='w-full flex flex-wrap flex-row justify-evenly max-w-screen-xl my-4'>
            {
              !isSearching ?
              fields.map(field=>(
                <FieldItem key={field.id} {...field } />
                //key is must value for react so It can differenetial between diffrent data
                // {...field} this is spreading other prorpties better than doing it manualy*/
              ))
              :
              materials.length >= 1 ?
              materials.map(material=>(
                <div key={material.id} className="w-full lg:w-4/12 h-96 p-4 flex ">
                  <div className='h-full w-full flex text-center flex-row flex-wrap justify-center items-center content-center bg-main-b rounded-lg '>
                    <Link to={`/material/${material.id}`} className='flex justify-center text-sub-a h-16 w-16'>
                      <img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt="material logo" className='h-full w-full' />
                    </Link>
                    <Link  to={`/material/${material.id}`} className='w-full text-3xl text-sub-a my-6'>{material.name}</Link>
                    <div>
                      <Button variant={3} complete className='my-6' >Private</Button>
                    </div>
                  </div>
                </div>
              ))
              : null
            }
          </div>
        </div>
      </div>
    </section>
  )
}



export default MaterialsPage