import React, { useState,useContext,useEffect } from 'react'

import axios from 'axios';

import { FiUpload,FiImage } from 'react-icons/fi';
import { BsFillArrowLeftSquareFill } from 'react-icons/bs'

import { profileContext } from '../../contexts/account'

import Button from '../button';
import { MdKeyboardArrowDown } from 'react-icons/md';




const ProfileModlar = () =>{
  const [modState,setModState] = useState<number>(0)
  const { profile,updateProfile } = useContext(profileContext)

  const [image,setImage] = useState<{data:any , preview:string}>({data:null , preview:`https://clashingblades.com/teachapi/uploads/${profile.image}`})
  
  const [name , setName] = useState<string>(profile.full_name)
  const [field , setField] = useState<number>(profile.field)
  const [years , setYears] = useState<number>(profile.exp_years)
  const [note , setNote] = useState<string>(profile.note)
  const [password , setPassword] = useState<string>("")
  const [nPassword , setNpassword] = useState<string>("")
  const [cNpassword , setCnpassword] = useState<string>("")


  const [fieldsdata , setFieldsData] = useState<Array<{id:number,name:string}>>([])
  useEffect(()=>{
    //get fields data so teacher can select
    const getData = async () =>{
      const getFields = await axios.get("/fields")
      setFieldsData(getFields.data)
    }
    getData()
  },[])
  const handleImageSelect = (e:React.ChangeEvent<HTMLInputElement>) =>{
    //this is used when a select image button is clicked on 
    //if exists to aviod if users doesnt select an image
    if(e.target.files){
      setImage({data:e.target.files[0],preview:URL.createObjectURL(e.target.files[0])})
    }
  }

  const handleImageUpload = () =>{
    //this is used when an the uplaod button is clicked on
    //to upload images we must use this way
    const Data = new FormData()
    Data.append('userid' , profile.id)
    Data.append('type' , profile.type)
    Data.append('imgfile', image.data)
    axios.post('/profile/imageupdate',Data).then(resp =>{
      updateProfile(profile)//update profile after changing image
      alert('Your image has been changed successfully')
      setModState(0)
  }).catch(err =>{
    console.log(err)
    alert('Something went wrong please try again later')
  })
  }

  const handleProfileUpdate = () =>{
    const data ={
      userid:profile.id,
      type:profile.type,
      fname:name
    }
    axios.post('/profile/updateinfo',data)
      .then(resp =>{
        setModState(0)
        updateProfile(profile)
        alert('Profile has been added successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }
  const handleProfileTeacherUpdate = () =>{
    const data ={
      userid:profile.id,
      type:profile.type,
      note:note,
      fname:name,
      field:field,
      years:years
    }
    axios.post('/profile/updateinfoteach',data)
      .then(resp =>{
        setModState(0)
        updateProfile(profile)
        alert('Profile has been added successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }

  const handlePasswordUpdate = () =>{
    if(nPassword.length < 8) {
      alert("Password must be 8 characters or more")
    }else if(nPassword !== cNpassword){
      alert("New password doesnt match confirm new password")
    }else{
      const data ={
        userid:profile.id,
        pass:password,
        newpass:nPassword
      }
      axios.post('/profile/updatepassword',data)
        .then(resp =>{
          setModState(0)
          alert('Password has been updated successfully')
      }).catch(err =>{
        console.log(err)
				err.toJSON().status === 403 ? 
				alert('Wrong Password')
				: alert('Something went wrong , try again later')

        })
    }

  }

  return(
    <div className='flex flex-wrap rounded-lg flex-row content-start bg-main-b w-96 z-20 text-white p-4'>
      <div className={modState ? 'w-full my-4' : 'hidden' }>
        <BsFillArrowLeftSquareFill onClick={()=>setModState(0)} className='h-8 w-8 hover:cursor-pointer'/>
      </div>  
      <div className={modState ? 'hidden' :'w-full my-4'}>
        <div className='text-xl font-medium'>Edit Profile</div>
        <div>Adding more info to your profile will allow others to know more about you which will make them do a better judgement </div>
      </div>
    {
      //to have a naviagtion like inside the modlar
      modState === 0 ?
      (<div className='w-full text-center flex flex-col'>
        <Button className='my-2' onClick={()=>setModState(1)} variant={3} complete>Edit Image</Button>   
        <Button className='my-2' onClick={()=>setModState(2)} variant={3} complete>Edit Info</Button>      
        <Button className='my-2' onClick={()=>setModState(3)} variant={3} complete>Change Password</Button>              
      </div> )
    //when first button is clicked
    : modState === 1 ?
    (<div className='w-full text-center flex flex-row flex-wrap justify-center items-center'>
      <div className='h-64 w-full rounded-lg mb-8'>
        <img src={image.preview} alt='upload img' className='w-full h-full rounded-lg' />
      </div>
      <label className="w-5/12  mx-2 flex flex-col items-center py-2 bg-sub-a text-main-b rounded-lg shadow-lg tracking-wide uppercase border-2 border-transparent cursor-pointer hover:bg-main-b hover:text-sub-a hover:border-sub-a">
          <FiImage className='h-8 w-8' />
          <input type='file' className="hidden" onChange={handleImageSelect} />
      </label>     
      {image.data ?
        <button onClick={handleImageUpload} className="w-5/12  mx-2 flex flex-col items-center py-2 bg-sub-a text-main-b rounded-lg shadow-lg tracking-wide uppercase border-2 border-transparent cursor-pointer hover:bg-main-b hover:text-sub-a hover:border-sub-a">
          <FiUpload className='h-8 w-8' />
       </button> 
      : null
      }
    </div>  )     
    : modState === 2 ?
    (<div className='w-full text-center flex flex-row flex-wrap justify-center items-center'>
      <div className='w-full'>
        <input value={name} onChange={(e)=>setName(e.target.value)} type="text" placeholder="Full Name" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
      </div> 
      {
        profile.type===2 ? 
        (
          <>
            <div className="relative w-full mb-4 ">
              <span className='absolute text-main-b top-3 right-2 text-2xl'>
                <MdKeyboardArrowDown />
              </span>
              <select  value={field} onChange={(e)=>setField(parseInt(e.target.value))}  className="w-full p-2.5 rounded-lg text-gray-500 bg-white border outline-none appearance-none ">
                {
                  fieldsdata.length > 0 ? 
                  fieldsdata.map(field=>(
                    <option key={field.id} value={field.id}>{field.name}</option>
                  ))
                  : null
                }
              </select>
            </div>
            <div className='w-full'>
              <input value={years} onChange={(e)=>setYears(parseInt(e.target.value))} type="number" placeholder="Years of Experince" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
            </div>
            <div className="w-full">
              <textarea className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none form-control block px-3 py-1.5 text-base font-normal bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0" 
                rows={3}
                placeholder="Your message"
                value={note}
                onChange={(e)=>setNote(e.target.value)}
              />

            </div>

          </>
        ) : null
      }
      <Button onClick={profile.type === 1 ? handleProfileUpdate : handleProfileTeacherUpdate} complete variant={3}>Save Changes</Button>
    </div>  )             
    :
    (<div className='w-full text-center flex flex-row flex-wrap justify-center items-center'>
    <div className='w-full'>
      <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="Old Password" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
    </div> 
    <div className='w-full'>
      <input value={nPassword} onChange={(e)=>setNpassword(e.target.value)} type="password" placeholder="New Password" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
    </div> 
    <div className='w-full'>
      <input value={cNpassword} onChange={(e)=>setCnpassword(e.target.value)} type="password" placeholder="Confirm New Password" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
    </div>                 
    <Button onClick={handlePasswordUpdate} complete variant={3}>Update Password</Button>
    </div>  )  
      
    }
                         
    </div>
  )
}



export default ProfileModlar