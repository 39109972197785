import React,{ useContext, useEffect, useState } from 'react'

import axios from 'axios';

import { profileContext } from '../contexts/account';

import Rating from '@mui/material/Rating';

const Rate = ({oldrate,userid}:{oldrate:number,userid:string | undefined}) =>{
  const { profile } = useContext(profileContext)
  const [rate , setRate ] = useState<number>(oldrate)
  useEffect(()=>{
    setRate(oldrate)
  },[oldrate])
  const handleRate = (event:React.SyntheticEvent,value: number | null)=>{
    setRate(value ? value : 0);
    const datar ={
      userid:userid,
      rateby:profile.id,
      rate:value ? value*2 : 0,
    }
    axios.post('/rate/send',datar)
      .then(resp =>{
        alert('You have rated this user')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong , try again later')
      })
  }
  return(
    <div className="flex justify-center items-center">
    <Rating
      value={rate}
      precision={0.5}
      onChange={handleRate}
    />
  </div>
  )
}


export default Rate 