import React, { useState,useEffect, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import axios from 'axios'

import Button from '../components/button'
import IconInput from '../components/iconinput'

import { HiOutlineMail } from 'react-icons/hi'
import { RiLockPasswordLine } from 'react-icons/ri'
import { MdKeyboardArrowDown,MdPermIdentity } from 'react-icons/md'
import { BsPersonCircle } from 'react-icons/bs'
import { IoIosCheckmarkCircle , IoIosCloseCircle } from 'react-icons/io'
import { profileContext } from '../contexts/account'


const SignupPage = ({email,setEmail}:{email:string;setEmail:Function}) =>{
  const [fname,setFname] = useState<string>("")
  const [password,setPassword] = useState<string>("")
  const [cpassword,setCPassword] = useState<string>("")
  const [type,setType] = useState<number>(1)
	const [alertController , setAlertController] = useState<{type:0|1|2,message:string}>({type:0,message:""})
	const { profile } = useContext(profileContext)
	const navigate = useNavigate()
	useEffect(()=>{
		if(profile.id){
			navigate('/profile')
		}
	},[navigate,profile])
	const handleSignUp = (e:React.MouseEvent<HTMLElement>)=>{
    e.preventDefault()
		if(!email || !fname || !password){//to make sure the form is filled
			setAlertController({type:2,message:'Please fill name ,email and your password'})
		}else if(!email.includes('@') || !email.includes('.com')){//to make sure this is an email
			setAlertController({type:2,message:'Please enter a vaild email'})
		}else if(password.length < 8){//make sure that password has 8 values
			setAlertController({type:2,message:'Your password must be 8 characters'})
		}else if(password !== cpassword){//make sure that password&confirm password are the same
			setAlertController({type:2,message:'Password and confirm pasword are not the same'})
		}
		else{
			axios.post('/signup',{
				email:email,
				fname:fname,
				password:password,
				type:type
			})
			.then(resp =>{
				setTimeout(()=>{
					navigate('/login')
				},2500)
				setAlertController({type:1,message:'Your account has been created successfully'})
			})
			.catch(err =>{
				console.log(err)
				//if backend send error with status of 406 then the email is already used
				err.toJSON().status === 406 ? 
				setAlertController({type:2,message:'There is account with the same email'})
				: setAlertController({type:2,message:'Something went wrong , try again later'})
		})			
		}
  }

  return(
    <section className='bggrad min-h-screen flex justify-center items-center flex-wrap flex-row p-12'>
      <main className="bg-main-b max-w-lg w-full p-8 md:p-12 rounded-lg shadow-2xl">
				<section>
						<h3 className="font-bold text-2xl text-sub-a">Join Online Teach Now!</h3>
						<p className="text-gray-300 pt-2">Create your account.</p>
				</section>
				<section className="mt-10">
						<form className="flex flex-col">
							<IconInput onChange={(e)=>setFname(e.target.value)} value={fname}  containerClass='my-4' className='pl-12'  type="text" placeholder='Full Name' icon={BsPersonCircle} iconClass="text-main-b text-2xl group-focus-within:text-sub-a transition-colors duration-300" />         
							<IconInput onChange={(e)=>setEmail(e.target.value)} value={email} containerClass='my-4' className='pl-12'  type="email" placeholder='Email' icon={HiOutlineMail} iconClass="text-main-b text-2xl group-focus-within:text-sub-a transition-colors duration-300" />         
							<IconInput onChange={(e)=>setPassword(e.target.value)} value={password} containerClass='my-4' className='pl-12'  type="password" placeholder='Password' icon={RiLockPasswordLine} iconClass="text-main-b text-2xl group-focus-within:text-sub-a transition-colors duration-300" />         
							<IconInput onChange={(e)=>setCPassword(e.target.value)} value={cpassword}  containerClass='my-4' className='pl-12'  type="password" placeholder='Confirm Password' icon={RiLockPasswordLine} iconClass="text-main-b text-2xl group-focus-within:text-sub-a transition-colors duration-300" />         
							<div className="group bg-white flex items-center border border-l-0 rounded-md my-4">
									<div className="px-3 py-2.5 text-2xl h-full rounded-l-md bg-white text-main-b group-focus-within:text-sub-a transition-colors duration-300 " >
											<MdPermIdentity />
									</div>
									<div className="relative h-full w-full ">
											<span className='absolute top-3 right-2 text-2xl'>
											<MdKeyboardArrowDown/>
											</span>
									<select value={type} onChange={(e)=>setType(parseInt(e.target.value))} className="w-full p-2.5 text-gray-500 bg-white border outline-none appearance-none focus:border-indigo-600">
											<option value={1}>Student</option>
											<option value={2}>Teacher</option>
									</select>
							</div>
							</div>                                                                  
							<Button onClick={handleSignUp} variant={3} complete className='mt-8'>Sign UP</Button>
						</form>
				</section>
				<div className={`${alertController.type === 1 ? 'bg-green-400' : alertController.type === 2 ? 'bg-red-500' :'hidden' } rounded-lg mt-8 p-2 flex`}>
					<span className='text-2xl flex text-center justify-center items-center mr-2'>
						{
							alertController.type === 1 ?(
								<IoIosCheckmarkCircle/>
							) :(
								<IoIosCloseCircle/>
							)
						}
					</span>
					{alertController.message}
				</div>
      </main>

    </section>
  )

}


export default SignupPage