import React from 'react'
import { IconType } from 'react-icons'

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, React.AriaAttributes  {
  icon:IconType ;
  iconClass?:string;
  containerClass?:string;
}

//

const IconInput = (props:InputProps)=>{
  const {className,iconClass,containerClass,icon,...rest} = props  
  return(
    <div className={`${containerClass} group relative flex w-full flex-wrap items-stretch`}>
      <span className="z-10 h-full leading-snug font-normal text-center absolute bg-transparent rounded text-base items-center justify-center pl-3 py-3">
        <props.icon className={iconClass} />
      </span>
      <input className={`${className} px-3 py-3 relative bg-white rounded text-sm border outline-none focus:outline-none focus:ring w-full pl-10`} {...rest} />
    </div>  
  )

  }




export default IconInput