import React from 'react'

//ButtonProps is added so we can use react on methods like onClick ,onMouseEnter etc
export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, React.AriaAttributes  {
  variant?:number //Variant is the color of button depending on the color schema
  complete?:boolean //Complete is that button is outlined or have full background: ;
}

//

const Button = (props:ButtonProps)=>{
  const {children,className,complete,variant,...rest} = props  
  return variant === 1 ? (
    <button {...rest} className={className + ` ${complete ? 'text-white bg-main-a hover:border-main-a hover:text-main-a ' : 'text-main-a border-main-a hover:bg-main-a hover:text-white'} rounded-sm border-2 border-transparent uppercase py-2 px-8 hover:bg-transparent  transition-colors duration-400`}>
      {children}
    </button>
  ):
  variant === 2 ? (
    <button {...rest} className={className + ` ${complete ? 'text-sub-a bg-main-b hover:border-main-b hover:text-main-b ' : 'text-main-b border-main-b hover:bg-main-b hover:text-sub-a'} rounded-sm border-2 border-transparent uppercase py-2 px-8 hover:bg-transparent  transition-colors duration-400`}>
      {children}
    </button>
  ):
  variant === 3 ? (
    <button {...rest} className={className + ` text-main-b rounded-sm border-2 border-transparent bg-sub-a uppercase py-2 px-8 hover:bg-transparent hover:border-sub-a hover:text-sub-a transition-colors duration-400`}>
      {children}
    </button>
  ):
  (
    <button {...rest} className={className + ` text-sub-a rounded-sm border-2 border-transparent bg-sub-b uppercase py-2 px-8 hover:bg-transparent hover:border-sub-b hover:text-sub-b transition-colors duration-400`}>
      {children}
    </button>
  )

}




export default Button