import React,{ useState,useContext, useEffect } from 'react'

import axios from 'axios';

import { Link,useNavigate,useLocation } from 'react-router-dom';


import Button from '../components/button'
import IconInput from '../components/iconinput'

import { HiOutlineMail } from 'react-icons/hi'
import { RiLockPasswordLine } from 'react-icons/ri'

import { IoIosCheckmarkCircle , IoIosCloseCircle } from 'react-icons/io'

import { profileContext } from '../contexts/account'


const LoginPage = () =>{
  const [email,setEmail] = useState<string>("")
  const [password,setPassword] = useState<string>("")
  const [alertController , setAlertController] = useState<{type:0|1|2,message:string}>({type:0,message:""})
	const navigate = useNavigate();//this is used route user to profile after login
  const location = useLocation();
  const { updateProfile , profile} = useContext(profileContext)
	useEffect(()=>{
		if(profile.id){
			navigate('/profile')
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[location,navigate])
  const handleLogin = (e:React.MouseEvent<HTMLElement>)=>{
    e.preventDefault()
		if(!email || !password){//to make sure the form is filled
			setAlertController({type:2,message:'Please email and password'})
		}else if(!email.includes('@') || !email.includes('.com')){//to make sure this is an email
			setAlertController({type:2,message:'Please enter a vaild email'})
		}else{
			axios.post('/userlogin',{
				email:email,
				password:password,
			})
			.then(resp =>{
				setAlertController({type:1,message:'You have logged successfully'})
        updateProfile(resp.data)
				setTimeout(()=>{
					navigate('/profile')
				},2500)
			})
			.catch(err =>{
				console.log(err)
        //if backend send error with status of 403 then it wrong password and so on
				err.toJSON().status === 403 ? 
				setAlertController({type:2,message:'Wrong Password'})
        : err.toJSON().status === 404 ? 
				setAlertController({type:2,message:'There is no account with this email'})
				: setAlertController({type:2,message:'Something went wrong , try again later'})
		})			
		}
  }
  return(
    <section className='bggrad min-h-screen flex justify-center items-center flex-wrap flex-row p-12'>
      <main className="bg-main-b max-w-lg w-full p-8 md:p-12 rounded-lg shadow-2xl">
        <section>
          <h3 className="font-bold text-2xl text-sub-a">Welcome to Online Teach</h3>
          <p className="text-gray-300 pt-2">Sign in to your account.</p>
        </section>
        <section className="mt-10">
          <form className="flex flex-col" method="POST" action="#">
            <IconInput onChange={(e)=>setEmail(e.target.value)} value={email} containerClass='my-4' className='pl-12'  type="email" placeholder='Email' icon={HiOutlineMail} iconClass="text-main-b text-2xl group-focus-within:text-sub-a transition-colors duration-300" />         
            <IconInput onChange={(e)=>setPassword(e.target.value)} value={password} containerClass='my-4' className='pl-12'  type="password" placeholder='Password' icon={RiLockPasswordLine} iconClass="text-main-b text-2xl group-focus-within:text-sub-a transition-colors duration-300" />         
            <Button onClick={handleLogin} variant={3} complete className='my-4'>Sign In</Button>
          </form>
        </section>
				<div className={`${alertController.type === 1 ? 'bg-green-400' : alertController.type === 2 ? 'bg-red-500' :'hidden' } rounded-lg mt-8 p-2 flex`}>
					<span className='text-2xl flex text-center justify-center items-center mr-2'>
						{
							alertController.type === 1 ?(
								<IoIosCheckmarkCircle/>
							) :(
								<IoIosCloseCircle/>
							)
						}
					</span>
					{alertController.message}
				</div>        
        <div className="max-w-lg mx-auto text-center mt-12 mb-6">
            <p className="text-white">Don't have an account? <Link to="/signup" className="font-bold hover:underline">Sign up</Link>.</p>
        </div>
      </main>

    </section>
  )

}


export default LoginPage