import { createContext } from 'react';

import Profile from './account'
import Messenger from './messages'

const AppContext = createContext();





export function AppWrapper({ children }) {
  return (
    <AppContext.Provider value={{title:'online Tech'}} >
        <Profile>
          <Messenger>
            {children}
          </Messenger>
        </Profile>
    </AppContext.Provider>
  );
}

