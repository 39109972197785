import React, { useState,useContext,useEffect } from 'react'

import axios from 'axios';


import { profileContext } from '../../contexts/account'

import Button from '../button';
import { MdKeyboardArrowDown } from 'react-icons/md';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface courseItemsProps{
  id:number;
  name:string;
  material_id:number;
  price:number;
  teacher_id:number;
  startTime:string;
  period:string;
  meetings:number;
  description:string;
}


const sqlToJs = (date:string) =>{
  var dateStr=date; //returned from mysql timestamp/datetime field
  var a=dateStr.split("T");
  var d=a[0].split("-");
  var t:any=a[1].split(":");
  var formatedDate = new Date(parseInt(d[0]),(parseInt(d[1])-1),parseInt(d[2]),parseInt(t[0]),parseInt(t[1]),parseInt(t[2]));
  return formatedDate
}

const CourseModlar = ({data}:{data:courseItemsProps}) =>{
  const { profile,updateProfile } = useContext(profileContext)  
  const [name , setName] = useState<string>("")
  const [field , setField] = useState<number>(0)
  const [price , setPrice] = useState<number>()
  const [material , setMaterial] = useState<number>(0)
  const [meetings , setMeetings] = useState<number>(3)
  const [period , setPeriod] = useState<string>("2 Weeks")
  const [note , setNote] = useState<string>("Describe your course")
  const [date , setDate] = useState(new Date())

  const [fieldsdata , setFieldsData] = useState<Array<{id:number,name:string}>>([])
  const [materialsdata , setMaterialsData] = useState<Array<{id:number,name:string,image:string,field:number}>>([])

  useEffect(()=>{
    //get all fields when its first initialized 
    const getData = async () =>{
      const getFields = await axios.get("/fields")
      setField(getFields.data[0].id)
      setFieldsData(getFields.data)
    }
    const getEditData = async () =>{
      if(data){
        setName(data.name)
        const getMaterial = await axios.get(`/material/${data.material_id}`)
        setField(getMaterial.data.field)   
        setPrice(data.price)
        setMeetings(data.meetings)
        setPeriod(data.period)
        setDate(sqlToJs(data.startTime))
        setNote(data.description)
        setMaterial(data.material_id)
      }
    }    
    getData()
    getEditData()
  },[data])

  useEffect(()=>{
    //get all materials when a field a selected
    const getData = async () =>{
      const getMaterials = await axios.get(`/materialsfield/${field}`)
      setMaterialsData(getMaterials.data)
      if(getMaterials.data.length > 0){
        setMaterial(getMaterials.data[0].id)
      }
    }
    getData()
  },[field])
 
 
  const handleAdd = () =>{
    const sentdata ={
      teacher:profile.id,
      material:material,
      name:name,
      price:price,
      period:period,
      startTime:date,
      meet:meetings,
      description:note
    }
    axios.post('/course/add',sentdata)
      .then(resp =>{
        updateProfile(profile)
        alert('Course has been added successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }

  const handleEdit = () =>{
    const sentdata ={
      id:data.id,
      material:material,
      name:name,
      price:price,
      period:period,
      startTime:date,
      meet:meetings,
      description:note
    }
    axios.post('/course/edit',sentdata)
      .then(resp =>{
        updateProfile(profile)
        alert('Course has been edited successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }


  return(
    <div className='modlarwrapper '>
      <div className="h-full overflow-y-scroll scroll">
        <div className='w-full my-4'>
          <div className='text-xl font-medium'>{data ? "Edit" : "Add"} Course</div>
        </div>
        <div className='w-full text-center flex flex-row flex-wrap justify-center items-center'>
          <div className='w-full'>
            <input value={name} onChange={(e)=>setName(e.target.value)} type="text" placeholder="Course Name" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
          </div> 
          <div className="relative w-full mb-4 ">
            <span className='absolute text-main-b top-3 right-2 text-2xl'>
              <MdKeyboardArrowDown />
            </span>
            <select  value={field} onChange={(e)=>setField(parseInt(e.target.value))}  className="w-full p-2.5 rounded-lg text-gray-500 bg-white border outline-none appearance-none ">
              {
                fieldsdata.length > 0 ? 
                fieldsdata.map(field=>(
                  <option key={field.id} value={field.id}>{field.name}</option>
                ))
                : null
              }
            </select>
          </div>
          <div className="relative w-full mb-4 ">
            <span className='absolute text-main-b top-3 right-2 text-2xl'>
              <MdKeyboardArrowDown />
            </span>
            <select  value={material} onChange={(e)=>setMaterial(parseInt(e.target.value))}  className="w-full p-2.5 rounded-lg text-gray-500 bg-white border outline-none appearance-none ">
              {
                materialsdata.length > 0 ? 
                materialsdata.map(material=>(
                  <option key={material.id} value={material.id}>{material.name}</option>
                ))
                : null
              }
            </select>
          </div>
          <div className='w-full'>
            <input value={price} onChange={(e)=>setPrice(parseInt(e.target.value))} type="number" placeholder="Enroll Price" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
          </div>      
          <div className='w-full'>
            <input value={meetings} onChange={(e)=>setMeetings(parseInt(e.target.value))} type="number" placeholder="Number Of Meetings" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
          </div>
          <div className='w-full'>
            <input value={period} onChange={(e)=>setPeriod(e.target.value)} type="text" placeholder="Course Period" className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none" />
          </div> 
        <div className='w-full'>
          <DatePicker className='w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b'  outline-none selected={date} onChange={(date:Date) => setDate(date)} />      </div>            
        <div className="w-full">
          <textarea className="w-full mb-3 p-2.5 pl-3 rounded-md text-main-a focus-within:text-main-b  outline-none form-control block px-3 py-1.5 text-base font-normal bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0" 
            rows={7}
            placeholder="Description"
            value={note}
            onChange={(e)=>setNote(e.target.value)}
          />
        </div>
        {
          data ?  
          <Button onClick={handleEdit}  complete variant={3}>Edit Course</Button>
          : 
          <Button onClick={handleAdd}  complete variant={3}>Add Course</Button>
        }

        </div>              

      </div>
   

    </div>
  )
}


export default CourseModlar