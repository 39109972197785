import React from 'react'

import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"


const AboutPage = () =>{
  return(
    <section >
      <div className='w-full courses h-96 flex flex-wrap flex-row justify-center content-center items-center text-center px-4 lg:px-12' style={{backgroundImage: 'url("/assets/courses_header.svg")'}}>
        <div className='w-full text-6xl font-medium mb-4'>Teachers</div>
        <div className='text-xl max-w-2xl mt-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod autem error quisquam unde aspernatur delectus totam adipisci.</div>
      </div>
      <div className='px-4 lg:px-20 w-full py-16 flex flex-wrap flex-row '>
        <div className='w-1/2 border-r border-sub-a pr-6 '>
          <div className='font-bold text-6xl text-main-a'><span className='text-main-b'>About</span> us</div>
          <p className='text-sub-b my-4 text-1xl'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officiis, vero ex sunt omnis eius nemo placeat, quae saepe magnam enim veniam quidem eos rerum ipsa, assumenda in iure labore sit,Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ullam tenetur, odio vero ut perspiciatis sint repellat! Veniam ad nesciunt, soluta, fuga dignissimos aspernatur laudantium deserunt, aperiam saepe inventore harum!</p>
          <div className='my-4 text-3xl'>Lorem ipsum dolor sit amet consectetur adipisicing elit. </div>
          <div className='flex justify-center items-center'>
            <img src='assets/logo.png' className='lg:h-64' alt="logo" />
          </div>
        </div>
        <div className='w-1/2 flex flex-row flex-wrap justify-center items-center'>
          <div className='min-h-80 md:h-96 w-1/2 xl:w-1/3 2xl:w-1/4 px-2 overflow-hidden md:px-8 my-2 flex justify-center flex-wrap content-start'>
            <img className='rounded-full ' src={'/assets/team/a.jpg'}  alt={"member-1"} />
            <div className='text-center w-full'>
              <div className='font-bold text-main-b my-1'>Yamen Massalha</div>
              <div className='text-sub-b my-1 mb-3'>Co-Founder</div>
              <div className='flex my-1 justify-center text-sub-a'>
                <div className='text-xl mx-2 first:ml-0'>
                  <a href='https://www.facebook.com/'>
                    <FaFacebookF/>
                  </a>
                </div> 
                <div className='text-xl mx-2 first:ml-0'>
                  <a href='https://www.twitter.com/'>
                    <FaTwitter />
                  </a>
                </div> 
                <div className='text-xl mx-2 first:ml-0'>
                  <a href='https://www.instagram.com/'>
                    <FaInstagram />
                  </a>
                </div>                         
              </div>        
            </div>
          </div>
          <div className='min-h-80 md:h-96 w-1/2 xl:w-1/3 2xl:w-1/4 px-2 overflow-hidden md:px-8 my-2 flex justify-center flex-wrap content-start'>
            <img className='rounded-full ' src={'/assets/team/b.jpg'}  alt={"member-2"} />
            <div className='text-center w-full'>
              <div className='font-bold text-main-b my-1'>Shoaa Sliman</div>
              <div className='text-sub-b my-1 mb-3'>Co-Founder</div>
              <div className='flex my-1 justify-center text-sub-a'>
                <div className='text-xl mx-2 first:ml-0'>
                  <a href='https://www.facebook.com/'>
                    <FaFacebookF/>
                  </a>
                </div> 
                <div className='text-xl mx-2 first:ml-0'>
                  <a href='https://www.twitter.com/'>
                    <FaTwitter />
                  </a>
                </div> 
                <div className='text-xl mx-2 first:ml-0'>
                  <a href='https://www.instagram.com/'>
                    <FaInstagram />
                  </a>
                </div>                         
              </div>        
            </div>
          </div>        
        </div>

      </div>
    </section>
  )
}


export default AboutPage