import React,{ useContext, useState,useEffect } from 'react'

import axios from 'axios'
import { Link } from 'react-router-dom'

import { BsFillPencilFill,BsTrashFill } from 'react-icons/bs'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { GoSearch } from 'react-icons/go'
import { CgProfile } from 'react-icons/cg'

import IconInput from '../components/iconinput'
import Button from '../components/button'
import Modlar from './modlar'

import { profileContext } from '../contexts/account'



const UserRow = ({entery,setModal,setEdata}:{setModal:Function,setEdata:Function,entery:{id:number,balance:number,email:string,type:number}}) =>{
  const editGo = ()=>{
    setEdata(entery)
    setModal({type:5,active:true})
  }
  return(
    <tr className="even:bg-main-b odd:bg-main-a text-white border-b group">
      <td className="group-last:rounded-bl-md px-6 py-4 whitespace-nowrap text-sm font-medium ">{entery.id}</td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.email}
      </td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.type === 1 ? "Student" : entery.type === 2 ? "Teacher" : "Admin"}
      </td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.balance}
      </td>
      <td className="text-sm text-gray-900 font-light px-2 whitespace-nowrap">
      <Button onClick={editGo} complete variant={3} className="flex px-3 rounded-lg justify-center items-center" >
        <BsFillPencilFill/>
      </Button>
      </td>
      <td className="group-last:rounded-br-md text-sm text-gray-900 font-light px-2 whitespace-nowrap">
        <Link to={`/${entery.type === 1 ? 'student' : 'teacher'}/${entery.id}`}>
          <Button complete variant={2} className="flex px-3 rounded-lg justify-center items-center bg-green-700 hover:bg-sub-b" >
            <CgProfile />
          </Button>
        </Link>
      </td>                                                                                  
    </tr>
  )
}

const FieldRow = ({entery,setModal,setEdata,deleteGo}:{deleteGo:Function,setModal:Function,setEdata:Function,entery:{id:number,name:string}}) =>{
  const editGo = ()=>{
    setEdata(entery)
    setModal({type:4,active:true})
  }

  return(
    <tr className="even:bg-main-b odd:bg-main-a text-white border-b group">
      <td className="group-last:rounded-bl-md px-6 py-4 whitespace-nowrap text-sm font-medium ">{entery.id}</td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.name}
      </td>

      <td className="text-sm text-gray-900 font-light px-2 whitespace-nowrap">
      <Button onClick={editGo} complete variant={3} className="flex px-3 rounded-lg justify-center items-center" >
        <BsFillPencilFill/>
      </Button>
      </td>
      <td className="group-last:rounded-br-md text-sm text-gray-900 font-light px-2 whitespace-nowrap">
        <Button onClick={()=>deleteGo(entery.id)} complete variant={2} className="flex mx-3 px-4 justify-center items-center bg-red-600 hover:border-red-600 hover:text-red-600 " >
          <BsTrashFill/>
        </Button>
      </td>                                                                                  
    </tr>
  )
}

const MaterialRow = ({entery,setModal,setEdata,deleteGo}:{deleteGo:Function,setModal:Function,setEdata:Function,entery:{id:number,name:string,image:string,field:number}}) =>{
  const editGo = ()=>{
    setEdata(entery)
    setModal({type:3,active:true})
  }

  return(
    <tr className="even:bg-main-b odd:bg-main-a text-white border-b group">
      <td className="group-last:rounded-bl-md px-6 py-4 whitespace-nowrap text-sm font-medium ">{entery.id}</td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.name}
      </td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        <img alt="file" className='h-8 w-8' src={`https://clashingblades.com/api/teachapi/uploads/${entery.image}`} />
      </td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.field}
      </td>            
      <td className="text-sm text-gray-900 font-light px-2 whitespace-nowrap">
      <Button onClick={editGo} complete variant={3} className="flex px-3 rounded-lg justify-center items-center" >
        <BsFillPencilFill/>
      </Button>
      </td>
      <td className="group-last:rounded-br-md text-sm text-gray-900 font-light px-2 whitespace-nowrap">
        <Button onClick={()=>deleteGo(entery.id)} complete variant={2} className="flex mx-3 px-4 justify-center items-center bg-red-600 hover:border-red-600 hover:text-red-600 " >
          <BsTrashFill/>
        </Button>
      </td>                                                                                  
    </tr>
  )
}

const CouponRow = ({entery,setModal,setEdata,deleteGo}:{deleteGo:Function,setModal:Function,setEdata:Function,entery:{id:number,code:string,uses:number,value:number}}) =>{
  const editGo = ()=>{
    setEdata(entery)
    setModal({type:6,active:true})
  }

  return(
    <tr className="even:bg-main-b odd:bg-main-a text-white border-b group">
      <td className="group-last:rounded-bl-md px-6 py-4 whitespace-nowrap text-sm font-medium ">{entery.id}</td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.code}
      </td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        {entery.uses}
      </td>
      <td className="text-sm font-light px-2 py-3 whitespace-nowrap">
        ${entery.value}
      </td>
      <td className="text-sm text-gray-900 font-light px-2 whitespace-nowrap">
      <Button onClick={editGo} complete variant={3} className="flex px-3 rounded-lg justify-center items-center" >
        <BsFillPencilFill/>
      </Button>
      </td>
      <td className="group-last:rounded-br-md text-sm text-gray-900 font-light px-2 whitespace-nowrap">
        <Button onClick={()=>deleteGo(entery.id)} complete variant={2} className="flex mx-3 px-4 justify-center items-center bg-red-600 hover:border-red-600 hover:text-red-600 " >
          <BsTrashFill/>
        </Button>
      </td>                                                                                  
    </tr>
  )
}

const DataShow = ({type}:{type:number}) =>{
  const [thedata , setData] = useState<Array<any>>([])
  const { profile } = useContext(profileContext)
  const [filter , setfilter] = useState<{sort:number,num:number,limit:number , state:number}>({sort:1 , num:10 , limit:100,state:1 })
  const [count , setCount] = useState<number>(0)
  const [dataShow , setDataShow] = useState<{num:number,active:number,start:number,end:number}>({num:0,active:1,start:0,end:0 })  
  const [search , setSearch] = useState<string>('')
  const [editdata , setEdata] = useState({})

  const url = 
  type === 1 ? "materials" :
  type === 2 ? "fields" : 
  type === 3 ? "users" :
  type === 4 ? "coupons" :   null
  const [ modal , setModal] = useState<{type:number,active:boolean}>({type:1,active:false}) // used to open Modal when it needed
  const getData = async () =>{
    const bodyParameters = {type:profile.type ,filter:filter}
    try {
      const res = await axios.post(`/admin/get${url}`,bodyParameters);
      res.data.data.length > 0 ? setData(res.data.data) : setData([])
      setCount(res.data.count[0]["COUNT(*)"])
    }catch(err){
      console.log(err)
    }
  }
  const handelSearch = (e:React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if(e.target.value){
      searchForData(e.target.value)
    }else{
      getData()
    }
  };
  // const handelChange = (e:number , value:number) =>{
  //   let data= dataShow
  //   if(value === dataShow.num ){
  //     data.active = value
  //     data.start=(value-1)*filter.num 
  //     data.end = count-1
  //   }else{
  //     data.active = value
  //     data.start=(value-1)*filter.num 
  //     data.end=(value-1)*filter.num +filter.num
  //   }
  //   onFilterChange(4,(dataShow.active+9)*filter.num)
  //   setDataShow({...data})
  // }
  const filterChange = () =>{
    const n:number = ((count-1) / filter.num) + 1
    setDataShow({num:n , start:0 , end :filter.num , active:1})
  }

  const onFilterChange = (type:number,data:number) =>{
    let filt =filter
    type === 1 ? filt.sort=data :
    type === 2 ? filt.num=data :
    type === 4 ? filt.state=data :
    filt.limit=data
    setfilter({...filt})
    filterChange()
    getData()
  }
  const searchForData = async (searchfor:string) =>{
    const bodyParameters = {type:profile.type ,search:searchfor}
    try {
      const res = await axios.post(`/admin/get${url}/${searchfor}`,bodyParameters);
      setData(res.data)
    }catch(err){
      console.log(err)
    }
  }

  const deleteGo = (deleteid:number) =>{
    const data = {id:deleteid, type:profile.type}
    try {
      axios.post(`/admin/delete${url}`,data)
      .then(res =>{
        alert("Item has been deleted")
        getData()
      });
    }catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    if(profile.type === 27){
      getData()      
      const n = ((count-1) / filter.num) + 1
      setDataShow({num:n , start:0 , end :filter.num , active:1})
    }      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile,count,type])

  return(
    <div className='flex flex-row flex-wrap py-6 px-8'>
      <div className='w-full flex flex-row items-center justify-around text-sub-a bg-main-b rounded-md py-6 px-4'>
        <div className='capitalize'>
          Total {url} : {count}
        </div>
        <div className="relative h-full w-36 ">
          <span className='absolute text-main-b top-3 right-2 text-2xl'>
            <MdKeyboardArrowDown />
          </span>
          <select  value={filter.sort} onChange={(e)=>onFilterChange(1,parseInt(e.target.value))}  className="w-full p-2.5 rounded-lg text-gray-500 bg-white border outline-none appearance-none ">
              <option value="" disabled >Sort By</option>
              <option value={1} selected>Descending</option>
              <option value={2}>Ascending</option>
          </select>
        </div>
        <div className="relative h-full w-24 ">
          <span className='absolute text-main-b top-3 right-2 text-2xl'>
            <MdKeyboardArrowDown />
          </span>
          <select value={filter.num} onChange={(e)=>onFilterChange(2,parseInt(e.target.value))} className="w-full p-2.5 rounded-lg text-gray-500 bg-white border outline-none appearance-none ">
              <option value="" disabled >Number</option>
              <option value={10} selected>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>  
              <option value={100}>100</option>                                              
          </select>
        </div>    
        <div>
          <IconInput value={search} onChange={handelSearch} className='rounded-lg'  type="text" placeholder='Search ' icon={GoSearch} iconClass="text-main-b text-xl" />         
        </div>  
        <div className='flex'>
          <Button className='capitalize' onClick={()=>{setModal({type:type+2,active:true});setEdata({})}}  variant={3} >Add {url}</Button>
        </div>           
      </div>
      <div className="w-full flex flex-col">
        <div className="overflow-x-auto ">
          <div className="py-2 inline-block min-w-full">
            <div className="overflow-hidden">
              {
                type === 1 ?
                <table className="min-w-full">
                  <thead className="bg-sub-a border-b">
                    <tr>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        ID
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Name
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Image
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Field
                      </th>                                            
                      <th scope="col" className="w-20 text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Edit
                      </th>                             
                      <th scope="col" className="w-20  text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Delete
                      </th>                            
                    </tr>
                  </thead>
                <tbody>
                {
                thedata.length > 0 ?
                thedata.slice(dataShow.start,dataShow.end).map(dat=>(
                  <MaterialRow deleteGo={deleteGo} entery={dat} setEdata={setEdata} setModal={setModal} key={`${type}-${dat.id}`} {...dat}  />
                  ))
                  : null
                }                         
                </tbody>
                </table>
                :type === 2 ?
                <table className="min-w-full">
                  <thead className="bg-sub-a border-b">
                    <tr>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        ID
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Name
                      </th>
                      <th scope="col" className="w-20 text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Edit
                      </th>                             
                      <th scope="col" className="w-20  text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Delete
                      </th>                            
                    </tr>
                  </thead>
                <tbody>
                {
                thedata.length > 0 ?
                thedata.slice(dataShow.start,dataShow.end).map(dat=>(
                  <FieldRow deleteGo={deleteGo} entery={dat} setEdata={setEdata} setModal={setModal} key={`${type}-${dat.id}`} {...dat}  />
                  ))
                  : null
                }                         
                </tbody>
                </table>
                :type === 3 ?
                <table className="min-w-full">
                  <thead className="bg-sub-a border-b">
                    <tr>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        ID
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Email
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Type
                      </th>
                      <th scope="col" className="w-20 text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Balance
                      </th>
                      <th scope="col" className="w-20 text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Edit
                      </th>                             
                      <th scope="col" className="w-20  text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Vist
                      </th>                            
                    </tr>
                  </thead>
                <tbody>
                {
                thedata.length > 0 ?
                thedata.slice(dataShow.start,dataShow.end).map(dat=>(
                  <UserRow entery={dat} setEdata={setEdata} setModal={setModal} key={`${type}-${dat.id}`} {...dat}  />
                  ))
                  : null
                }                         
                </tbody>
                </table>
                :
                <table className="min-w-full">
                  <thead className="bg-sub-a border-b">
                    <tr>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        ID
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Code
                      </th>
                      <th scope="col" className="text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Uses
                      </th>
                      <th scope="col" className="w-20 text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Value
                      </th>
                      <th scope="col" className="w-20 text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Edit
                      </th>                             
                      <th scope="col" className="w-20  text-sm font-medium first:rounded-tl-md last:rounded-tr-md text-gray-900 px-2 py-4 text-left">
                        Delete
                      </th>                            
                    </tr>
                  </thead>
                <tbody>
                {
                thedata.length > 0 ?
                thedata.slice(dataShow.start,dataShow.end).map(dat=>(
                  <CouponRow deleteGo={deleteGo} entery={dat} setEdata={setEdata} setModal={setModal} key={`${type}-${dat.id}`} {...dat}  />
                  ))
                  : null
                }                         
                </tbody>
                </table>
              }

            </div>
          </div>
        </div>
      </div>
      <Modlar getData={getData} type={modal.type} active={modal.active} editModal={setModal} data={editdata}  />     
    </div>
  )
}




export default DataShow