import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import { useParams,Link } from 'react-router-dom';


import { AiFillStar } from 'react-icons/ai'
import { BsFillPersonFill,BsCalendarDateFill,BsFillBriefcaseFill } from 'react-icons/bs'
import { MdPlayLesson } from 'react-icons/md'



import Button from '../../components/button';
import Modlar from '../../components/modlar';

import { profileContext } from '../../contexts/account';

interface courseItemsProps{
  id:number;
  name:string;
  material_id:number;
  price:number;
  teacher_id:number;
  startTime:string;
  period:string;
  meetings:number;
  description:string;
}

interface materialItemProps {
  id:number;
  name:string;
  image:string;
  field:number
}

interface teacherProps {
  userid:number;
  full_name:string;
  image:string;
  field:number;
  exp_years:number;
  rating:number;
  cnum:number;
  snum:number;
  created_at:string;
}


const Infor= ({title,data}:{title:string;data:string|number;}) =>{
  return(
  <div className='w-full flex my-4  text-center lg:text-left '>
    <div className='w-4/12 italic'>
      {title}:
    </div>
    <div className='w-8/12 font-medium'>
      {data}
    </div>
  </div>
  )}

const CourseProfilePage = ()=>{
  const [course , setCourse] = useState<courseItemsProps>({id:0,name:'',material_id:0,price:0,teacher_id:0,startTime:'',period:'',meetings:0,description:''})
  const [material , setMaterial ] = useState<materialItemProps>({id:0,name:'Teach Online',image:'black.jpg',field:0})
  const [teacher , setTeacher ] = useState<teacherProps>({userid:0,full_name:'Teacher Online',image:'black.jpg',field:0,exp_years:4,rating:0,cnum:0,snum:0,created_at:'2022-02-22'}) 
  const [eduF , setEduF ] = useState<{id:number;name:string;}>({id:0,name:'Teaching Online',}) 
  const { id } = useParams();
  const { profile,updateProfile } = useContext(profileContext)
  const [ modal , setModal] = useState<{type:number,active:boolean,data:any}>({type:1,active:false,data:course}) // used to open Modal when it needed
  const [ enrolled,setEnrolled ]= useState<boolean>(false)//use to check if student already enrolled or

  useEffect(()=>{
    //get course's data when first inilized 
    const getData = async () =>{
      try{
        const getCourse = await axios.get(`/courseid/${id}`)
        const getMaterial  = await axios.get(`/material/${getCourse.data.material_id}`)
        const getTeacher = await axios.get(`/teacher/${getCourse.data.teacher_id}`)
        const getRating = await axios.get(`/rate/getrate/${getCourse.data.teacher_id}`)
        const getTeacherCourses = await axios.get(`/coursesbyid/${getCourse.data.teacher_id}`)
        const getStudents = await axios.get(`/coursestudentsofteacher/${getCourse.data.teacher_id}`)
        const getEduf = await axios.get(`/fields/${getMaterial.data.field}`)
        setCourse(getCourse.data)
        setMaterial(getMaterial.data)
        setTeacher({...getTeacher.data,rating:getRating.data ? getRating.data / 2 : 0,cnum:getTeacherCourses.data.length ? getTeacherCourses.data.length : 0,snum:getStudents.data.length ? getStudents.data.length : 0})
        setEduF(getEduf.data)
      }catch(err){
        console.log(err)
      }
    }
    getData()
    if(profile.courses.length > 0){
      var enroll = profile.courses.some((course:{id:number,state:number})=>{ 
        return course.id === parseInt(id!) && course.state === 1
      });
      setEnrolled(enroll)
    }
  },[profile.courses,id])
  //Empty array means when component is created
  //Adding something inside the array means when the value of this is changed run the code
  //adding id only here means that when the page is visted so it will only run once

  const handleDelete = () =>{
    const sentdata ={
      cousrseid:id,
    }
    axios.post('/course/delete',sentdata)
      .then(resp =>{
        updateProfile(profile)
        alert('Course has been delete successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }

  const handleCancel = () =>{
    const sentdata ={
      courseid:id,
      studentid:profile.id,
      type:profile.type,
      costprice:course.price
    }
    axios.post('/course/cancel',sentdata)
      .then(resp =>{
        updateProfile(profile)
        alert('Course has been Cancelled successfully')
    }).catch(err =>{
      console.log(err)
      alert('Something went wrong please try again later')
      })
  }
  return(
    <section>
      <div className='courses h-96 flex flex-wrap flex-row justify-center content-center items-center text-center px-4 lg:px-12' style={{backgroundImage: 'url("/assets/courses_header.svg")'}}>
        <div className='w-36 h-36'>
          <img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt="material logo" className='filter brightness-0 h-full w-full' />
        </div>
        <div className='w-full text-6xl font-medium mt-8'>{course.name}</div>
      </div>    
      <div className='flex flex-wrap flex-row justify-centers bg-main-b'>
        <div className='w-full flex flex-wrap flex-row px-12 py-12 max-w-screen-xl'>
          <div className='w-full lg:w-4/12 flex flex-wrap flex-row justify-center content-start'>
            <div className='w-full flex justify-center lg:justify-start'>
              <img src={`https://clashingblades.com/api/teachapi/uploads/${teacher.image}`} className='h-40 w-40 rounded-full' alt='Teacher' />
            </div>
            <div className='w-full mt-4 text-center lg:text-left'>
              <Link className='text-3xl text-sub-a hover:underline font-medium tracking-wider' to={`/teacher/${teacher.userid}`}>{teacher.full_name}</Link>
              <div className='mt-4 flex flex-row flex-wrap text-left'>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><AiFillStar/> </div>
                  <div className='font-medium text-white w-56'> {teacher.rating} Rating </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><BsFillPersonFill/> </div>
                  <div className='font-medium text-white w-56'>{teacher.snum} Students </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><MdPlayLesson/> </div>
                  <div className='font-medium text-white w-56'>{teacher.cnum} Courses </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><BsCalendarDateFill/> </div>
                  <div className='font-medium text-white w-56'>Joined Since {teacher.created_at.split("T")[0]} </div>
                </div>
                <div className='w-full flex justify-center lg:justify-start items-center text-lg my-2'> 
                  <div className='mr-4 text-sub-a w-6'><BsFillBriefcaseFill/> </div>
                  <div className='font-medium text-white w-56'>Specialize at {eduF.name} for {teacher.exp_years} years </div>
                </div>
              </div>

            </div>
          </div>
          <div className='w-full lg:w-8/12 text-white mt-12'>
            {
              profile.id === teacher.userid ?
              <div className='flex'>
              <Button onClick={()=>setModal({type:7,active:true,data:course})} complete variant={1}>Edit</Button>
              <Button onClick={handleDelete} className= 'ml-12 bg-red-600 hover:border-red-600 hover:text-red-600 ' complete variant={2}>Delete</Button>

              </div>
              : null
            }
            <Infor title="Field" data={eduF.name} />
            <Infor title="Material" data={material.name} />
            <Infor title="Period" data={course.period} />
            <Infor title="Number of Meetings" data={course.meetings} />
            <Infor title="Start Time" data={course.startTime ? course.startTime.split("T")[0] : "2022-02-22" } />
            <Infor title="Enrolled Students" data={2000} />
            <div className='mt-2 italic'>Description:-</div>
            <p className='mt-1'>{course.description}</p>
            
            <div className='flex mt-8 justify-center lg:justify-start'>
              {
                enrolled ?
                <Button onClick={handleCancel} variant={1} className='text-sub-a bg-red-600 hover:bg-red-800' >Cancel Course</Button>
                :
                <Button onClick={()=>setModal!({type:8,active:true,data:{id:course.id,name:course.name,price:course.price} })} complete variant={3}>Enroll With ${course.price}</Button>

              }
            </div>

          </div>

        </div>

      </div>  
      <Modlar  type={modal.type} active={modal.active} editModal={setModal} data={modal.data}  />     
    </section>
  )
}



export default CourseProfilePage