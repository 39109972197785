import React,{ useEffect, useState } from 'react'

import IconInput from '../../components/iconinput'

import { GoSearch } from 'react-icons/go'


import Button from '../../components/button'
import { Link,useParams } from 'react-router-dom'
import axios from 'axios'

//Typescript types
interface courseItemsProps{
  id:number;
  name:string;
  material_id:number;
  price:number;
  teacher_id:number;
  startTime:string;
  period:string;
  meetings:number;
  description:string;
}

//This is the course item which is displayed with every course item
const CourseItem = ({id,name,material_id,description,price,teacher_id,period,startTime,meetings}:courseItemsProps) =>{
  const [material , setMaterial ] = useState<{id:number;name:string;image:string,education_field:number}>({
    id:0,
    name:'Teach Online',
    image:'black.jpg',
    education_field:0
  })
  const [teacher , setTeacher ] = useState<{id:number;full_name:string;}>({
    id:0,
    full_name:'Teacher Online',
  })  
  useEffect(()=>{
    //get course's material when first inilized 
    const getData = async () =>{
      const getMaterial = await axios.get(`/material/${material_id}`)
      const getTeacher = await axios.get(`/teacher/${teacher_id}`)
      setMaterial(getMaterial.data)
      setTeacher(getTeacher.data)
    }
    getData()
  },[material_id,teacher_id])

  return(
    <div className='w-full lg:w-4/12 px-8 my-6 lg:p-4 hover:-translate-y-2 hover:cursor-pointer transition-transform duration-400'>
      <Link to={`/privatecourse/${id}`} className='w-full h-80 text-sub-a rounded-md bg-main-a flex flex-wrap flex-row justify-center items-center content-center'>
        <div className='w-20 h-20'>
          <img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt="material logo" className='h-full w-full' />
        </div>
        <div className='w-full text-2xl mt-10 text-center'>
          {name}
        </div>
      </Link>
      <div className=''>
        <div className='text-sub-b italic'>
          Prof.{teacher.full_name}
        </div>
        <div className='px-8 h-72 lg:h-64 py-4 font-light'>
          {description}
        </div>
        <div className='flex justify-between'>
          <div>
            Period:{period}
          </div>
          <div>
            Start At :{startTime ? startTime.split("T")[0] : "2022-02-22"}
          </div>
        </div>
        <Button complete variant={1} className="w-full rounded-lg mt-4">
          Enroll With Only ${price}.00
        </Button>
      </div>
    </div>
  )
}

const PrivateMaterialProfilePage = ()=>{
  const [material , setMaterial ] = useState<{id:number;name:string;image:string,education_field:number}>({id:0,name:'Teach Online',image:"black.jpg",education_field:0})
  const [courses , setCourses] = useState<Array<courseItemsProps>>([])
  const [searchCourse , setSearchCourse ] = useState<Array<courseItemsProps>>([])
  const [search , setSearch] = useState<string>("")
  const [isSearching , setIsSearching] = useState<boolean>(false)

  const { id } = useParams();
  useEffect(()=>{
    //gets material data and its courses when page first initialized  
    const getData = async () =>{
      try{
        const getMaterial = await axios.get(`/material/${id}`)
        const getCourses = await axios.get(`/privatecoursesmatbyid/${id}`)
        setMaterial(getMaterial.data)
        setCourses(getCourses.data)
      }catch(err){
        console.log(err)
      }
    }
    getData()

  },[id])

  const handleSearch = async (e:React.ChangeEvent<HTMLInputElement>) =>{
    //this is used to enable search mode when there is a search
    const text = e.target.value
    setSearch(text)
    if(text.length > 0 ){
      const getTeachers = await axios.get(`/courses/search/${id}/${text}`)
      setSearchCourse(getTeachers.data)
      setIsSearching(true)
    }else{
      setIsSearching(false)
    }
  }  
  return(
    <section>
      <div className='courses h-96 flex flex-wrap flex-row justify-center content-center items-center text-center px-4 lg:px-12' style={{backgroundImage: 'url("/assets/courses_header.svg")'}}>
        <div className='w-36 h-36'>
          <img src={`https://clashingblades.com/api/teachapi/uploads/${material.image}`} alt="material logo" className='filter brightness-0 h-full w-full' />
        </div>
        <div className='w-full text-6xl font-medium mb-4 capitalize'>{material.name}</div>
      </div>
      <div className='min-h-screen bg-white'>
        <div className='w-full bg-main-a flex px-8 justify-center py-6'>
          <div className='w-10/12 lg:w-5/12 flex justify-center items-center'>
            <IconInput value={search} onChange={handleSearch} type="text" placeholder='Search for a course ....' icon={GoSearch} iconClass="text-main-b text-xl" />         
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='w-full flex flex-wrap flex-row justify-evenly max-w-screen-xl my-4'>
            {
              !isSearching ? 
              courses.length > 0 ?
              courses.map(course=>(
                <CourseItem key={course.id} {...course } />
                //key is must value for react so It can differenetial between diffrent data
                // {...course} this is spreading other prorpties better than doing it manualy*/
              ))
              : null
              : searchCourse.length > 0 ?
              searchCourse.map(course=>(
                <CourseItem key={course.id} {...course } />
                //When searching is foucsed it will switch to those
              ))
              : 
              <div>No Course has been found </div>              
            }
          </div>
        </div>
      </div>
    </section>
  )
}



export default PrivateMaterialProfilePage