import React , { useEffect, useContext } from 'react'

import { Link, useNavigate} from 'react-router-dom'


import { profileContext } from '../../contexts/account'

import { MdDangerous } from 'react-icons/md'

import StudentUserPage from './student'
import TeacherUserPage from './teacher'

const ProfilePage = ()=>{
  const { profile,updateProfile } = useContext(profileContext)
  const navigate = useNavigate()
  //this useeffect is used to check if user is logged on or not , the check happens when you enter the page or the profile context change
  //if user is not logged it will send him to login page
  useEffect(()=>{
    if(!profile.id){
//      navigate('/login')
    }
    updateProfile(profile)
    console.log('refreshed')
  },[profile,navigate,updateProfile])

  return profile.id ?(
    <>
      {
        profile.type  === 1 ?( 
          <StudentUserPage/>
        )
        : (
          <TeacherUserPage/>
        )
      }
    </>
  )
  : (
    <section className='h-screen flex justify-center items-center'>
        <div className="mt-12 mx-4 px-4 rounded-md bg-red-50 md:max-w-2xl md:mx-auto">
            <div className="py-3">
                <div className="flex">
                    <div>
                      <MdDangerous className='h-6 w-6 rounded-full text-red-500'/>
                    </div>
                    <div className="self-center ml-3">
                        <span className="text-red-600 font-semibold">
                            You are not logged in yet
                        </span>
                        <p className="text-red-600 mt-1">
                            Please go to the <Link className="underline" to={"/login"}>login</Link> page and signin with your email and password 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}




  export default ProfilePage